import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import en_GB from 'antd/lib/locale-provider/en_GB';
import uk_UA from 'antd/lib/locale-provider/uk_UA';
import {Locale} from "antd/lib/locale-provider";

import {TranslationsContext} from "./translationsContext";
import {Translations, TType} from "./translationsTypes";
import {TObject} from "../../types";


export interface WithTranslateProps {
		t: TType;
}

export const useTranslate = () => {
		const context = useContext(TranslationsContext);

		if (!context) {
				throw new Error("useTranslation should be used within a TranslationsContext");
		}

		const {language, setLanguage} = context;
		const intlObj = useIntl();

		const [antLocale, setAntLocale] = useState<Locale>(en_GB);
		const [languageExtended, setLanguageExtended] = useState<string>("en_GB");

		useEffect(() => {
				switch (language) {
						case Translations.en:
								setLanguageExtended("en-GB");
								return setAntLocale(en_GB);
						case Translations.uk:
								setLanguageExtended("uk-UA");
								return setAntLocale(uk_UA);
				}
		}, [language]);

		const t = useCallback(
				(message: string, variables?: TObject): string => {
						if (!message) return "";

						return intlObj.formatMessage({id: message}, variables);
				},
				[intlObj],
		);


		return useMemo(() => ({t, language, setLanguage, antLocale, languageExtended}), [
				t,
				language,
				setLanguage,
				antLocale,
				languageExtended,
		]);
};
