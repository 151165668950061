import styled from "styled-components";
import {Collapse, Menu} from "antd";

import {device} from "../../../../../constants";


export const SkillsFilterStyled = styled.div`

`;

export const MenuStyled = styled(Menu)`
  display: flex;

  .ant-menu-item {
    margin: 0 !important;
    padding: 5px 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    height: fit-content;

    &.ant-menu-item-selected {
      background-color: transparent !important;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  .ant-menu-item-active {
    background: transparent;
  }

  > .ant-menu-item {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    padding: 0;

    &:last-child {
      border: none;
    }

    .ant-menu {
      border: none;
    }

    .ant-menu-item-group-title {
      color: rgba(0, 0, 0, 0.45);
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      text-transform: uppercase;
      padding: 8px 16px 6px;
    }

    .ant-menu-item-only-child {
      &:hover {
        background: rgba(0, 0, 0, 0.06);
      }
    }
  }

  @media (${device.mobileMax}) {

  }
`

export const Panel = styled(Collapse.Panel)`

  .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 8px !important;
    background: transparent;
    border-radius: 0 !important;
    box-shadow: 0px -1px 0px 0px #F0F0F0 inset !important;

    .ant-collapse-header-text {
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: rgba(0, 0, 0, 0.85);
    }

    .ant-collapse-expand-icon {
      span {
        margin: 0 !important;

        svg {
          width: 16px;
          height: 16px;
          transform: rotate(90deg);
          color: #797979;
        }
      }
    }

    &[aria-expanded="true"] {
      .ant-collapse-expand-icon {
        span {
          svg {
            transform: rotate(-90deg) !important;
          }
        }
      }
    }
  }

  .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 0 !important;

    .ant-radio-button-wrapper {
      border-radius: 6px;
      border: none;
      background: transparent;
      color: rgba(0, 0, 0, 0.45);

      &.ant-radio-button-wrapper-checked {
        border: none;
        background: rgba(0, 0, 0, 0.1);
        color: #000;

        &:not(.ant-radio-button-wrapper-disabled):focus-within {
          box-shadow: unset;
        }
      }

      &:not(:first-child)::before {
        background-color: unset;
      }
    }
  }
`