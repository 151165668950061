import {useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";
import {Location} from "@remix-run/router";


const usePrevious = (value: Location): Location => {
    const ref = useRef<Location | null>(null);

    useEffect(() => {
        ref.current = value;
    })

    return ref.current!;
}

export const useLocationChange = (action: (location: Location, prevLocation: Location) => void) => {
    const location = useLocation();
    const prevLocation = usePrevious(location);

    useEffect(() => {
        action(location, prevLocation);
    }, [location]);
}

