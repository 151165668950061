import styled from "styled-components";
import {Input} from "antd";
import {CloseOutlined, StarFilled} from "@ant-design/icons";


export const StyledForm=styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 100%;

  h2, h4 {
    font-weight: 500;
    color: #000000;
    margin: 0;
  }

  h2 {
    font-size: 20px;
    line-height: 28px;
  }

  h4 {
    font-size: 14px;
    line-height: 22px;
  }

  p {
    margin: 0;
    font-size: 12px;
    line-height: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .info {
      background: #FAFAFA;
      border-radius: 4px;
      padding: 12px;

    }

    .comment, .rating, .email {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

    }

    .email {
      position: relative;

      .error {
        position: absolute;
        color: red;
        bottom: -20px;
      }
    }

    .rating {
      align-items: center;
    }

  }

  .control_buttons {
    display: flex;
    column-gap: 8px;

    > button {
      width: 100%;
    }
  }
`

export const StyledClose = styled(CloseOutlined)`
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
`

export const StyledStar = styled(StarFilled)`
  svg {
    height: 32px;
    width: 32px;
  }
`

export const StyledInput = styled(Input)`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  
  input{
    
  color: #8C8C8C;
  }
`

export const StyledTextArea = styled(Input.TextArea)`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #8C8C8C;
  border-radius: 8px;
  border: 1px solid #D9D9D9;

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`

