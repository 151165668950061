import {CSSProperties, ElementType, MouseEventHandler, ReactNode, RefCallback} from "react";
import {PartialDeep, SetRequired, Simplify} from "type-fest";
import {Props as FloaterProps} from "react-floater";


export type TooltipRenderProps = Simplify<
    BeaconRenderProps & {
    backProps: {
        'aria-label': string;
        'data-action': string;
        onClick: MouseEventHandler<HTMLElement>;
        role: string;
        title: string;
    };
    closeProps: {
        'aria-label': string;
        'data-action': string;
        onClick: MouseEventHandler<HTMLElement>;
        role: string;
        title: string;
    };
    primaryProps: {
        'aria-label': string;
        'data-action': string;
        onClick: MouseEventHandler<HTMLElement>;
        role: string;
        title: string;
    };
    skipProps: {
        'aria-label': string;
        'data-action': string;
        onClick: MouseEventHandler<HTMLElement>;
        role: string;
        title: string;
    };
    tooltipProps: {
        'aria-modal': boolean;
        ref: RefCallback<HTMLElement>;
        role: string;
    };
}
>;

export type Placement =
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'right'
    | 'right-start'
    | 'right-end';

export type StepMerged = Simplify<
    SetRequired<
        Step,
        | 'disableBeacon'
        | 'disableCloseOnEsc'
        | 'disableOverlay'
        | 'disableOverlayClose'
        | 'disableScrollParentFix'
        | 'disableScrolling'
        | 'event'
        | 'hideBackButton'
        | 'hideCloseButton'
        | 'hideFooter'
        | 'isFixed'
        | 'locale'
        | 'offset'
        | 'placement'
        | 'showProgress'
        | 'showSkipButton'
        | 'spotlightClicks'
        | 'spotlightPadding'
    > & {
    styles: Styles;
}
>;

export type BeaconRenderProps = {
    continuous: boolean;
    index: number;
    isLastStep: boolean;
    size: number;
    step: StepMerged;
};

export interface Locale {
    back?: ReactNode;
    close?: ReactNode;
    last?: ReactNode;
    next?: ReactNode;
    open?: ReactNode;
    skip?: ReactNode;
}

export interface StylesOptions {
    arrowColor: string;
    backgroundColor: string;
    beaconSize: number;
    overlayColor: string;
    primaryColor: string;
    spotlightShadow: string;
    textColor: string;
    width?: string | number;
    zIndex: number;
}

export interface Styles {
    beacon: CSSProperties;
    beaconInner: CSSProperties;
    beaconOuter: CSSProperties;
    buttonBack: CSSProperties;
    buttonClose: CSSProperties;
    buttonNext: CSSProperties;
    buttonSkip: CSSProperties;
    options: Partial<StylesOptions>;
    overlay: CSSProperties;
    overlayLegacy: CSSProperties;
    overlayLegacyCenter: CSSProperties;
    spotlight: CSSProperties;
    spotlightLegacy: CSSProperties;
    tooltip: CSSProperties;
    tooltipContainer: CSSProperties;
    tooltipContent: CSSProperties;
    tooltipFooter: CSSProperties;
    tooltipFooterSpacer: CSSProperties;
    tooltipTitle: CSSProperties;
}

export type BaseProps = {
    beaconComponent?: ElementType<BeaconRenderProps>;
    disableCloseOnEsc?: boolean;
    disableOverlay?: boolean;
    disableOverlayClose?: boolean;
    disableScrollParentFix?: boolean;
    disableScrolling?: boolean;
    floaterProps?: Partial<FloaterProps>;
    hideBackButton?: boolean;
    hideCloseButton?: boolean;
    locale?: Locale;
    nonce?: string;
    showProgress?: boolean;
    showSkipButton?: boolean;
    spotlightClicks?: boolean;
    spotlightPadding?: number;
    styles?: PartialDeep<Styles>;
    tooltipComponent?: ElementType<TooltipRenderProps>;
};

export type Step = Simplify<
    BaseProps & {
    content: ReactNode;
    disableBeacon?: boolean;
    event?: string;
    floaterProps?: FloaterProps;
    hideFooter?: boolean;
    isFixed?: boolean;
    offset?: number;
    placement?: Placement | 'auto' | 'center';
    placementBeacon?: Placement;
    target: string | HTMLElement;
    title?: ReactNode;
}
>;

export type TState = {
    run: boolean;
    steps: Step[];
    menuOpen: boolean;
    profileDropdownOpen: boolean;
    stepIndex: number;
};

export const initialState: TState = {
    run: false,
    steps: [],
    menuOpen: false,
    profileDropdownOpen: false,
    stepIndex: 0
};

export type useStepsResult = {
    handleProfileDropdownOpen: () => void;
    profileDropdownOpen: boolean;
    handleMenuOpen: () => void;
    menuOpen: boolean;
    joyrideComponent: any;
    resetRun: (run: boolean) => void;
    run: boolean;
}

export enum StepIds {
    menu = "menu",
    settings = "settings",
    support = "support",
    messenger = "messenger",
    notifications = "notifications",
    avatar = "avatar",
    lessons = "my-lessons",
    favourites = "my-favourites",
    schedule = "schedule",
}