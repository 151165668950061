import {notification} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";

import audioFile from "../../../assets/audio/notification.mp3";


const notificationSound = new Audio(audioFile);

export const TeachingMeInfoNotification = (message: string) => {

    notificationSound.play()
      .catch((e) => {
          console.log("Failed to start playback", e);
      });

    notification.info({
        message,
        placement: "topRight",
        duration: 7,
        className: "notification"
    });
};

export const TeachingMeSuccessNotification = (message: string) => {

    notification.success({
        message,
        placement: "topRight",
        duration: 5,
        className: "notification"
    });
};

export const TeachingMeWarnNotification = (message: string) => {

    notification.warn({
        message,
        placement: "topRight",
        duration: 10,
        className: "notification"
    });
};

export const TeachingMeErrorNotification = (message: string) => {

    notification.error({
        message,
        placement: "topRight",
        duration: 10,
        className: "notification",
        icon: <ExclamationCircleOutlined/>
    });
};
