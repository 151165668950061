import {ChangeEvent, FC, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {LeftOutlined} from "@ant-design/icons";

import {
    AvailabilityFilter,
    CountryFilter,
    PriceFilter, RatingFilter,
    SkillsFilter,
    StatusFilter
} from "../../../home-page/search-filter/filters";
import {Filter, RadioData, TagsRemoveMethodsBindings} from "../../../home-page";
import {SearchTags} from "../../../home-page/search-filter/search-tags";
import {SET_SEARCH_BUTTON_CLICKED} from "../../../../redux";
import {useDisableScroll} from "../../../../../hooks";
import {Country, setState} from "../../../../../types";
import {TType} from "../../../../../assets/translations";
import {
    FilterItem,
    FiltersWrapper,
    MobileSearchFilterStyled,
    StyledDivider,
    StyledInput
} from "./mobile-search-filter.styled";
import {SkillsIcon} from "../../../../../assets/images/svg-elements/skills";
import {StatusIcon} from "../../../../../assets/images/svg-elements/status";
import {LocationIcon} from "../../../../../assets/images/svg-elements/location";
import {WifiIcon} from "../../../../../assets/images/svg-elements/wifi";
import {ClockIcon} from "../../../../../assets/images/svg-elements/clock";
import {CityFilter} from "../../../home-page/search-filter/filters/city-filter";


interface Props {
    t: TType;
    searchDrawerOpen: boolean;
    setSearchDrawerOpen: setState<boolean>;
    tags: TagsRemoveMethodsBindings[];
    setTags: setState<TagsRemoveMethodsBindings[]>;
    performSearch: () => void;
    clearAll: () => void;
    applySearchText: (event: ChangeEvent<HTMLInputElement>) => void;
    filterData: Filter;
    setFilterData: setState<Filter>;
    storedCountries: Country[];
}

export const MobileSearchFilterComponent: FC<Props> = ({
                                                           t,
                                                           searchDrawerOpen,
                                                           setSearchDrawerOpen,
                                                           tags,
                                                           setTags,
                                                           clearAll,
                                                           performSearch,
                                                           filterData,
                                                           applySearchText,
                                                           setFilterData,
                                                           storedCountries
                                                       }) => {

    const dispatch = useDispatch();

    useDisableScroll(searchDrawerOpen);

    const search = () => {
        dispatch({type: SET_SEARCH_BUTTON_CLICKED, searchButtonClicked: true});

        performSearch();
        setSearchDrawerOpen(false);
    };

    const [countries, setCountries] = useState<RadioData[]>([]);

    const memoFilters = useMemo(() => [
        {
            panelKey: "1",
            title: t("searchpage.filters.skills.title"),
            icon: <SkillsIcon/>,
            content: <SkillsFilter setFilterData={setFilterData}
                                   setTags={setTags}/>
        },
        {
            panelKey: "2",
            title: t("searchpage.filters.prices.title"),
            icon: <SkillsIcon/>,
            content: <PriceFilter t={t}
                                  filterData={filterData}
                                  setFilterData={setFilterData}
                                  setTags={setTags}/>
        },
        {
            panelKey: "3",
            title: t("searchpage.filters.status.title"),
            icon: <StatusIcon/>,
            content: <StatusFilter t={t}
                                   filterData={filterData}
                                   setFilterData={setFilterData}
                                   setTags={setTags}/>
        },
        {
            panelKey: "4",
            title: t("searchpage.filters.city.title"),
            icon: <LocationIcon/>,
            content: <CityFilter t={t}
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    setTags={setTags}/>
        },
        {
            panelKey: "5",
            title: t("searchpage.filters.availability.title"),
            icon: <WifiIcon/>,
            content: <AvailabilityFilter t={t}
                                         filterData={filterData}
                                         setFilterData={setFilterData}
                                         setTags={setTags}/>
        },
        {
            panelKey: "6",
            title: t("searchpage.filters.rating.title"),
            icon: <ClockIcon/>,
            content: <RatingFilter t={t}
                                   filterData={filterData}
                                   setFilterData={setFilterData}
                                   setTags={setTags}/>
        },
    ], [countries]);

    useEffect(() => {
        if (storedCountries.length) {
            const countriesRadioData = storedCountries.map((country: Country) => ({
                value: country,
                name: country.name
            }));

            setCountries(countriesRadioData);
        }
    }, [storedCountries]);


    return <MobileSearchFilterStyled placement="top"
                                     closable={false}
                                     height="90vh"
                                     onClose={() => setSearchDrawerOpen(false)}
                                     open={searchDrawerOpen}>
        <div className="header">
            <LeftOutlined onClick={() => setSearchDrawerOpen(false)}/>
            <StyledInput data-cy="mobile-filter-search-input" placeholder={t("searchpage.search.placeholder")}
                         value={filterData.searchText}
                         onChange={applySearchText}/>
        </div>

        <StyledDivider/>
        {!!tags.length && <div data-cy="tablet-tags-mobile" className="tablet-tags">
            <SearchTags t={t} tags={tags} clearAll={clearAll}/>
        </div>}
        <FiltersWrapper ghost accordion defaultActiveKey={["1"]}>
            {memoFilters.map(({title, icon, content, panelKey}) =>
                <FilterItem data-cy="filter-item" key={panelKey}
                            header={<>{icon}<h2>{title}</h2></>}
                >
                    {content}
                </FilterItem>
            )}
        </FiltersWrapper>
        <div className="controls">
            <button className="cancel" onClick={() => setSearchDrawerOpen(false)}>{t("searchpage.cancel")}</button>
            <button data-cy='mobile-filter-search-button' className="search" onClick={search}>{t("searchpage.search")}</button>
        </div>
    </MobileSearchFilterStyled>;
};
