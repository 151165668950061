import {
    RESET_MEETING_INFO,
    UPDATE_ACTIVE_CAMERA,
    UPDATE_ACTIVE_MICROPHONE,
    UPDATE_ACTIVE_SPEAKER,
    UPDATE_IS_MUTED,
    UPDATE_IS_STARTED_VIDEO,
} from "./meetingConstants";


const initialState = {
    isMuted: true,
    isStartedVideo: false,
    activeMicrophone: "",
    activeSpeaker: "",
    activeCamera: "",
}

export function meetingStore(state = initialState, action) {
    const actions = {
        [UPDATE_IS_MUTED]: {
            ...state,
            isMuted: action.isMuted
        },
        [UPDATE_IS_STARTED_VIDEO]: {
            ...state,
            isStartedVideo: action.isStartedVideo
        },
        [UPDATE_ACTIVE_MICROPHONE]: {
            ...state,
            activeMicrophone: action.activeMicrophone
        },
        [UPDATE_ACTIVE_SPEAKER]: {
            ...state,
            activeSpeaker: action.activeSpeaker
        },
        [UPDATE_ACTIVE_CAMERA]: {
            ...state,
            activeCamera: action.activeCamera
        },
        [RESET_MEETING_INFO]: {
            ...initialState
        }
    }

    return actions[action.type] || state;
}