import {GET_NOTIFICATIONS} from "../endpoints";
import axiosClient from "../../config/axiosConfig";
import {TNotificationsResponse} from "../../types";


export const getNotifications = async (languageCode: string, page: number, pageSize = 3): Promise<TNotificationsResponse> => {
    return axiosClient.get(GET_NOTIFICATIONS(), {
        headers: {
            "Accept-Language": languageCode
        },
        params: {
            page,
            pageSize
        }
    });
}
