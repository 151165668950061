import {useEffect} from "react";
import {useMediaQuery} from "react-responsive";

import {device} from "../components/constants";


export const useWindowDimensions = (cb: Function) => {
    const isMobile = useMediaQuery({
        query: `(${device.mobileMax})`
    });

    const isTablet = useMediaQuery({
        query: `(${device.tabletMax})`
    });

    const isDesktop = useMediaQuery({
        query: `(${device.desktop})`
    });

    useEffect(() => {
        cb();
    }, [isDesktop, isTablet, isMobile]);

}