import {GET_CITIES} from "../endpoints";
import axiosClient from "../../config/axiosConfig";
import {TCitiesResponse} from "../../types";


export const getCities = async (languageCode: string, countryCode: number): Promise<TCitiesResponse> => {
    return axiosClient.get(GET_CITIES(), {
            headers: {
                "Accept-Language": languageCode
            },
            params: {
                "countryCode": countryCode
            }
        }
    );
}
