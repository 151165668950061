import {connect} from "react-redux";

import {TeachingMeHeaderComponent} from "./header-element";
import {RootState, SHOW_CHOOSE_ROLE_MODAL, SHOW_LOG_IN_MODAL} from "../../redux";
import {getToken} from "../../../api/tokenHeaderProvider";
import {Authority} from "../../../types";


const mapStateToProps = (state: RootState) => {
    return {
        avatarId: state.userStore.avatarId,
        accessToken: getToken(),
        firstName: state.userStore.firstName,
        lastName: state.userStore.lastName,
        isTeacher: state.tokensStore.jwtParsed.authority === Authority.ROLE_TEACHER,
        isShowChooseRole: state.modalsStore.showChooseRoleModal,
        isShowSignIn: state.modalsStore.showLogInModal,
        isShowSignUpModal: state.modalsStore.showSignUpModal,
        isShowConfirmEmailModal: state.modalsStore.showConfirmEmailModal,
        isShowRegistrationThirdStepModal: state.modalsStore.showRegistrationThirdStepModal,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    showChooseRoleModal: () => dispatch({type: SHOW_CHOOSE_ROLE_MODAL}),
    showLogInModal: () => dispatch({type: SHOW_LOG_IN_MODAL})
})

export const TeachingMeHeader = connect(mapStateToProps, mapDispatchToProps)(TeachingMeHeaderComponent);