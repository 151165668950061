import {connect} from "react-redux";

import {SkillsFilterComponent} from "./skills-filter";


const mapStateToProps = (state: any) => {
    return {
       storedCategories: state.userStore.categories,
    }
}


export const SkillsFilter = connect(mapStateToProps)(SkillsFilterComponent);
