export const SHOW_CHOOSE_ROLE_MODAL = "ShowChooseRoleModal";
export const HIDE_CHOOSE_ROLE_MODAL = "HideChooseRoleModal";

export const SHOW_LOG_IN_MODAL = "ShowLogInModal";
export const HIDE_LOG_IN_MODAL = "HideLogInModal";

export const FILL_LOGIN_INFO = "FillLoginInfo";

export const SHOW_SIGN_UP_MODAL = "ShowSignUpModal";
export const HIDE_SIGN_UP_MODAL = "HideSignUpModal";

export const SHOW_CONFIRM_EMAIL_MODAL = "ShowConfirmEmailModal";
export const HIDE_CONFIRM_EMAIL_MODAL = "HideConfirmEmailModal";

export const SHOW_REGISTRATION_THIRD_STEP_MODAL = "ShowRegistrationThirdStepModal";
export const HIDE_REGISTRATION_THIRD_STEP_MODAL = "HideRegistrationThirdStepModal";

export const SHOW_UPLOAD_RESULT_MODAL = "ShowUploadResultModal";
export const HIDE_UPLOAD_RESULT_MODAL = "HideUploadResultModal";

export const SHOW_REVIEW_RESULT_MODAL = "ShowReviewResultModal";
export const HIDE_REVIEW_RESULT_MODAL = "HideReviewResultModal";

export const SHOW_REVIEW_HOMEWORK_MODAL = "ShowReviewHomeworkModal";
export const HIDE_REVIEW_HOMEWORK_MODAL = "HideReviewHomeworkModal";

export const CLEAR_LOGIN_INFO = "ClearLoginInfo";