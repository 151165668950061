import uk from "./uk.json";
import en from "./en.json";
import {TObject} from "../../types";

export interface TType {
    (message: string, variables?: TObject): string;
}

export type TranslationsTypes = {
    [translation in Translations]: {
        [key: string]: any;
    };
};

export interface TranslationsProviderProps {
    messages: TranslationsTypes;
}

export enum Translations {
    en = "en",
    uk = "uk",
}

export const translations: TranslationsTypes = {
    uk,
    en
};

export const LANGUAGES_LS_KEY = "language";

export interface Language {
    language: keyof typeof Translations;
}
