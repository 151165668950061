import styled from "styled-components";


export const NotificationsListWrapper = styled.div`
  overflow: auto;
  max-height: 400px;
  max-width: 435px;
  overscroll-behavior: contain;

  .read-all {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000;
    cursor: pointer;
    padding: 20px 20px 0;
    text-align: end;
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
    background: transparent;
  }
`;