import styled from "styled-components";
import {Radio} from "antd";

import {device} from "../../../../../constants";


export const AvailabilityFilterStyled = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  > h2 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    width: fit-content;
  }

  .controls {
    display: flex;
    column-gap: 16px;
    margin-top: 16px;

    > button {
      width: 50%;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      padding: 6.4px 15px;
      cursor: pointer;

      &.apply {
        border-radius: 6px;
        border: 1px solid #18067D;
        background: #18067D;
        box-shadow: 0 4px 60px 0 rgba(24, 6, 125, 0.12);
        color: #fff;
      }

      &.reset {
        color: #18067D;
        border: none;
        background: transparent;
      }
    }
  }

  @media (${device.mobileMax}) {
    padding: 0;
  }

`;

export const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;


  .filter-radio {
    padding: 5px 0;

    .ant-radio-inner {
      background-color: #fff;
    }

    .ant-radio-checked > span.ant-radio-inner {
      border-color: #18067D;

      ::after {
        background-color: #18067D;
      }
    }
  }
`