import styled from 'styled-components';
import {Modal} from "antd";


export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;

  .ant-modal-body {
    min-width: 608px;
    width: 50vw;
    max-width: 800px;
    background: #FFFFFF;
    border-radius: 16px;
  }
`;


