import {CSSProperties, FC, MouseEvent, ReactElement} from "react";
import {LoadingOutlined} from "@ant-design/icons";

import {StyledButtonWrapper} from "./button.styled";


export enum ButtonTheme {
		BLACK = 'black',
		WHITE = 'white'
}

interface Props {
		children: string | ReactElement;
		theme?: ButtonTheme;
		onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
		style?: CSSProperties;
		disabled?: boolean;
		className?: string;
		loading?: boolean;
		dataAttr?: string;
}

export const StyledButton: FC<Props> = ({
																						children,
																						theme = ButtonTheme.BLACK,
																						onClick = () => {
																						},
																						style = {},
																						disabled = false,
																						className = '',
																						loading = false,
																						dataAttr,
																				}) => {


		return <StyledButtonWrapper className={theme.concat(` ${className}`)}
																onClick={onClick}
																style={style}
																data-cy={dataAttr}
																disabled={disabled || loading}>
				{loading && <LoadingOutlined/>}
				{children}
		</StyledButtonWrapper>;
};