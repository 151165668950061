import styled from "styled-components";
import {TweenOneGroup} from "rc-tween-one";

import {device} from "../../../../constants";


export const SearchTagsStyled = styled(TweenOneGroup)`
  display: flex;
  align-items: center;
  column-gap: 16px;
  overflow: auto;

  .search-tag {
    margin: 0;
    border-radius: 6px;
    border: 1px solid #E8E6F2;
    background: #E8E6F2;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    column-gap: 4px;
    color: #5D51A4;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .clear-all {
    color: #5D51A4;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
    white-space: nowrap;
  }

  ::-webkit-scrollbar {
    height: 6px;
  }

  @media (${device.tabletMax}) {
    flex-wrap: wrap;
    row-gap: 16px;
  }
`;