import {FC, useEffect, useState} from "react";
import {useSubscription} from "react-stomp-hooks";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {Avatar, Badge, Popover} from "antd";

import {TeachingMeInfoNotification} from "../../../notification";
import {NotificationMessages} from "../notifications-component";
import {WS_NOTIFICATIONS} from "../../../../../api/endpoints";
import {getAuthorizationHeader} from "../../../../../api/tokenHeaderProvider";
import {getNotifications} from "../../../../../api/user-api";
import {device} from "../../../../constants";
import {setState, TNotificationsResponse, StepIds} from "../../../../../types";
import {TType, useTranslate} from "../../../../../assets/translations";
import {NotificationsDropdownWrapper} from "./notifications-dropdown.styled";
import {BellIcon} from "../../../../../assets/images/svg-elements/bell";
import {SmsIcon} from "../../../../../assets/images/svg-elements/sms";


interface Props {
    t: TType;
    email: string;
    notificationsCount: number;
    setNotificationsCount: any;
    messagesCount: number;
    setMessagesCount: setState<number>;
    renderButtons?: boolean;
}

export const NotificationsDropdownComponent: FC<Props> = ({
                                                              t,
                                                              email,
                                                              notificationsCount,
                                                              setNotificationsCount,
                                                              messagesCount,
                                                              setMessagesCount,
                                                              renderButtons = true
                                                          }) => {

    const translations = useTranslate();

    const navigate = useNavigate();

    const isMobile = useMediaQuery({
        query: `(${device.tabletMax})`
    });

    const [page, setPage] = useState<number>(0);
    const [notifications, setNotifications] = useState<TNotificationsResponse>({} as TNotificationsResponse);

    useEffect(() => {
        fetchNotification();
    }, []);

    const handleNotification = (message: any) => {
        const data = JSON.parse(message.body);
        const notificationsData = {...notifications};

        notificationsData.data.notifications.unshift(data.notification);
        notificationsData.data.notifications.pop();

        setNotifications(notificationsData);
        setNotificationsCount(data.unreadNotifications);
        TeachingMeInfoNotification(data.notification?.description);
    }

    const getNextPageData = () => {
        setPage(prev => prev + 1);

        getNotifications(translations.language, page)
            .then((response) => {
                response.data.notifications = notifications?.data?.notifications?.concat(response.data.notifications);
                setNotifications(response);
                setNotificationsCount(response?.data?.unreadNotifications);
            });
    }

    useSubscription(WS_NOTIFICATIONS(email), handleNotification, {Authorization: getAuthorizationHeader()});

    const fetchNotification = () => {
        setPage(prev => prev + 1);

        getNotifications(translations.language, page ? page : 0)
            .then((response) => {
                setNotifications(response);

                setNotificationsCount(response?.data?.unreadNotifications);
            });
    }

    const goToMessagesPage = () => {
        navigate("/m");
    }

    const goToNotificationsPage = () => {
        navigate("/notifications");
    }


    if (!renderButtons) {
        return null;
    }

    return <NotificationsDropdownWrapper>
        {isMobile ?
            <Badge count={notificationsCount} size="small" offset={[0, "10%"]}>
                <Avatar data-cy="notifications-icon-mobile" icon={<BellIcon/>} onClick={goToNotificationsPage}/>
            </Badge>
            :
            <Popover overlayClassName="notifications-popover"
                     trigger="click"
                     content={<NotificationMessages t={t}
                                                    notifications={notifications}
                                                    setNotifications={setNotifications}
                                                    notificationsCount={notificationsCount}
                                                    setNotificationsCount={setNotificationsCount}
                                                    setPage={setPage}
                                                    getNextPageData={getNextPageData}/>}
            >

                <Badge count={notificationsCount} size={"default"} offset={[0, "10%"]}>
                    <Avatar data-cy="notifications-icon" icon={<BellIcon id={StepIds.notifications}/>}/>
                </Badge>
            </Popover>
        }
        <Badge count={messagesCount} size={isMobile ? "small" : "default"} offset={[0, "10%"]}>
            <div data-cy="messenger-icon">
                <Avatar icon={<SmsIcon id={isMobile ? "" : StepIds.messenger}/>} onClick={goToMessagesPage}/>
            </div>
        </Badge>
    </NotificationsDropdownWrapper>;
};