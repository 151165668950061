import {legacy_createStore as createStore, combineReducers, Store} from 'redux';

import {meetingStore, modalsStore, tokensStore, userStore} from "../slices";


const rootReducer = combineReducers({modalsStore, tokensStore, userStore, meetingStore});

const store: Store = createStore(rootReducer);

export type RootState = ReturnType<typeof store.getState>

export const rootStore: Store<RootState> = store;