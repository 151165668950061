import styled from 'styled-components';

import {device} from "../../constants";


export const StyledEndOfList = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0;
  padding: 40px 0;

  @media (${device.tabletMax}) {
    padding: 20px 0;
  }
`