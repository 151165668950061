import {POST_FEEDBACK} from "../endpoints";
import axiosClient from "../../config/axiosConfig";
import {TFeedbackBody} from "../../types";


export const postFeedback = async (feedbackBody: TFeedbackBody): Promise<void> => {
    return axiosClient.post(POST_FEEDBACK(), feedbackBody, {
        headers: {
            "Content-type": "application/json",
        },
    });
}