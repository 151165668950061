import {FC, CSSProperties} from "react";

import {TType} from "../../../assets/translations";
import {StyledEndOfList} from "./end-message.styled";


interface Props {
    t: TType;
    style?: CSSProperties;
}

export const EndMessage: FC<Props> = ({t, style = {}}) => {

    return <StyledEndOfList style={style}>{t("endMessage")}</StyledEndOfList>;
};
