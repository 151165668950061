import {connect} from "react-redux";

import {MobileSearchFilterComponent} from "./mobile-search-filter";


const mapStateToProps = (state: any) => {
    return {
        storedCountries: state.userStore.countries,
    }
}


export const MobileSearchFilter = connect(mapStateToProps)(MobileSearchFilterComponent);
