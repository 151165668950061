import styled from "styled-components";

export const StyledButtonWrapper = styled.button`
  padding: 6.4px 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  border-radius: 8px;
  cursor: pointer;
  transition: hover, 0.4s;

  &.white {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
    color: rgba(0, 0, 0, 0.85);
  }

  &.black {
    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
    color: #FFFFFF;
  }

  &:not([disabled]) {
    &.white:hover {
      border: 1px solid #000000;
    }

    &.black:hover {
      background: #FFFFFF;
      color: #000000;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);;
    border-color: #d9dce2;
    background: #f4f6fa;
  }
`;
