import {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useMediaQuery} from "react-responsive";

import {device} from "../../../../../constants";
import {Filter, RadioData, TagsRemoveMethodsBindings} from "../../search-input-filter.types";
import {CityData, Country, setState} from "../../../../../../types";
import {TType} from "../../../../../../assets/translations";
import {CityFilterStyled, RadioGroup} from "./city-filter.styled";
import {TranslationsContext} from "../../../../../../assets/translations/translationsContext";
import {getCities} from "../../../../../../api/user-api";
import {Dropdown, Option} from "../../../../../elements/dropdown";


interface Props {
  t: TType;
  filterData: Filter;
  setFilterData: setState<Filter>;
  setTags: setState<TagsRemoveMethodsBindings[]>;
  tagName?: string;
}

export const CityFilter: FC<Props> = ({
                                        t,
                                        filterData,
                                        setFilterData,
                                        setTags,
                                        tagName = "countryTag",
                                      }) => {

  const isMobile = useMediaQuery({
    query: `(${device.mobileMax})`
  });

  const {language} = useContext(TranslationsContext);
  const [cities, setCities] = useState<Option[]>([])
  const [selectedCity, setSelectedCity] = useState<number | undefined>(undefined);

  useEffect(() => {
    fetchCities()
  }, []);

  useEffect(() => {
    if (selectedCity) applyCity()
  }, [selectedCity]);

  const disableOptions = useCallback((selectedCode: number | undefined, option: CityData) => {
    const {code, name} = option;
    return selectedCode === code ? {value: code, name, disabled: true} : {value: code, name, disabled: false}
  }, []);

  const fetchCities = () => {
    getCities(language, 1)
      .then(({data}) => {
        if (data.length) setCities(data.map((option) => disableOptions(selectedCity, option)))
      })
  }

  function applyCity(selectedCityValue = selectedCity) {
    if (selectedCityValue == null) return

    setTags(prev => {
      const tag = prev.find(tag => tag.id === tagName)

      if (tag) {
        return [...prev.map(tag => tag.id === tagName ? {
          ...tag,
          title: getCityByCode(selectedCity)?.name || "",
          removeTagMethod: resetCity
        } : {...tag})];
      } else {
        return [...prev, {id: tagName, title: getCityByCode(selectedCity)?.name || "", removeTagMethod: resetCity}]
      }
    });

    setFilterData(prev => ({...prev, cities: [selectedCity]}))
  }

  function resetCity() {
    setTags(prev => prev.filter(tag => tag.id !== tagName));
    setFilterData(prev => ({...prev, cities: undefined}));
    setSelectedCity(undefined);
  }

  function getCityByCode(code?: number) {
    return cities.find((city) => city.value === code);
  }

  function onDropdownChange(dropDownValue: any) {
    setSelectedCity(dropDownValue);
  }

  return <CityFilterStyled isMobile={isMobile}>
    <Dropdown dropdownMatchSelectWidth={true} open={isMobile ? undefined : true} options={cities}
              value={getCityByCode(selectedCity)?.name} setValue={onDropdownChange}
              placeholder={t("profile.settings.account.cityPlaceholder")}/>
  </CityFilterStyled>
};
