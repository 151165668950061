import {connect} from "react-redux";

import {FormComponent} from "./shared-form";


const mapStateToProps = (state: any) => {
    return {
        email: state.userStore.email
    }
}

export const Form = connect(mapStateToProps)(FormComponent);
