import {CustomLoaderStyled} from "./custom-loader.styled";
import {CSSProperties, FC} from "react";
import classNames from "classnames";

interface Props {
  wrapperHeight?: string;
  loaderSize?: string;
  wrapperStyles?: CSSProperties;
}

function isSpinnerSmall(loaderSize: string) {
  const unit = loaderSize.slice(-2);
  if (unit !== 'px') return;

  const number = Number(loaderSize.slice(0, -2));
  return number <= 32;
}

export const CustomLoader: FC<Props> = ({
                                          wrapperHeight = "auto",
                                          loaderSize = '15vw',
                                          wrapperStyles = {}
                                        }) => {

  const small = isSpinnerSmall(loaderSize);

  return (
    <CustomLoaderStyled wrapperHeight={wrapperHeight} loaderSize={loaderSize} style={wrapperStyles}>
      <div className="spinner">
        <div className={classNames({'spinner-circle': true, 'spinner-circle-small': small})}></div>
      </div>
    </CustomLoaderStyled>
  );
};