import {FC, PropsWithChildren} from "react";
import {StompSessionProvider} from "react-stomp-hooks";

import {BASE_URL} from "../api/endpoints";
import {translations, TranslationsProvider} from "../assets/translations";
import {sendAnalyticEvent} from "../services/user-service";


const AppProviders: FC<PropsWithChildren> = ({children}) => {


    return <TranslationsProvider messages={translations}>
        <StompSessionProvider url={`${BASE_URL()}/users/v1/open/notifications-websocket`}
                              reconnectDelay={1}
                              onStompError={() => {
                                  sendAnalyticEvent("stomp errors", {"timestamp": new Date().toUTCString()});
                                  // navigate("/logout")
                              }}
            // connectHeaders={{Authorization: getAuthorizationHeader()}}
            // debug={STOMP => console.log({STOMP})}
            // onConnect={() => console.log({STOMP_CONNECT: "TCP connection successfully established"})}
        >
            {children}
        </StompSessionProvider>
    </TranslationsProvider>
};

export default AppProviders;
