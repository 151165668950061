import {GET_COUNTRIES} from "../endpoints";
import axiosClient from "../../config/axiosConfig";
import {TCountriesResponse} from "../../types";


export const getCountries = async (languageCode: string): Promise<TCountriesResponse> => {
    return axiosClient.get(GET_COUNTRIES(), {
        headers: {
            "Accept-Language": languageCode
        }
    });
}
