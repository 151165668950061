const size = {
    mobileMin: '320px',
    mobileMax: '767px',
    tabletMin: '768px',
    tabletMax: '1023px',
    desktop: '1024px',
    smallDesktop: '1280px',
}

const device = {
    mobileMin: `min-width: ${size.mobileMin}`,
    mobileMax: `max-width: ${size.mobileMax}`,
    tabletMin: `min-width: ${size.tabletMin}`,
    tabletMax: `max-width: ${size.tabletMax}`,
    desktop: `min-width: ${size.desktop}`,
    smallDesktop: `max-width: ${size.smallDesktop}`,
};

export {size, device};