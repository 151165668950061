import {FC, useState} from 'react';
import {InputNumber, Slider} from "antd";
import {useMediaQuery} from "react-responsive";

import {Filter, TagsRemoveMethodsBindings} from "../../search-input-filter.types";
import {device} from "../../../../../constants";
import {setState} from "../../../../../../types";
import {TType} from "../../../../../../assets/translations";
import {RatingFilterStyled, StyledStarIcon} from "./rating-filter.styled";


interface Props {
    t: TType;
    filterData: Filter;
    setFilterData: setState<Filter>;
    setTags: setState<TagsRemoveMethodsBindings[]>;
    tagName?: string;
}

const min = 1;
const max = 5;

export const RatingFilter: FC<Props> = ({t, filterData, setFilterData, setTags, tagName = "ratingTag"}) => {

    const isMobile = useMediaQuery({
        query: `(${device.mobileMax})`
    });

    const [minRating, setMinRating] = useState<number>(filterData.ratingFrom || min);

    const [maxRating, setMaxRating] = useState<number>(filterData.ratingTo || max);

    const changeRange = (value: [number, number]) => {
        setMinRating(value[0]);
        setMaxRating(value[1]);

        isMobile && applyRating(value[0], value[1]);
    }

    const changeMaxValue = (value: number | null) => {
        if (value! > max) {
            setMaxRating(max);
        } else {
            if (value! < minRating) {
                setMaxRating(minRating);
            } else {
                setMaxRating(value!);
            }
        }
    }

    const changeMinValue = (value: number | null) => {
        if (value! < min) {
            setMinRating(min);
        } else {
            if (value! > maxRating) {
                setMinRating(maxRating);
            } else {
                setMinRating(value!);
            }
        }

    }

    const resetRating = () => {
        setTags(prev => prev.filter(tag => tag.id !== tagName));
        setFilterData(prev => ({...prev, ratingFrom: undefined, ratingTo: undefined}));

        setMinRating(min);
        setMaxRating(max);
    };

    const applyRating = (minRatingValue = minRating, maxRatingValue = maxRating) => {
        setTags(prev => {
            const tag = prev.find(tag => tag.id === tagName);

            const title = `★${minRatingValue} - ★${maxRatingValue}`;

            if (tag) {
                return [...prev.map(tag => tag.id === tagName ? {
                    ...tag,
                    title,
                    removeTagMethod: resetRating
                } : {...tag})];
            } else {
                return [...prev, {id: tagName, title, removeTagMethod: resetRating}];
            }
        });

        setFilterData(prev => ({...prev, ratingFrom: minRatingValue, ratingTo: maxRatingValue}));

    };


    return <RatingFilterStyled>
        {!isMobile && <h2>{t("searchpage.filters.rating.title")}</h2>}
        <div className="slider">
            <div className="values">
                <p>{min}</p>
                <p>{max}</p>
            </div>
            <Slider range={{draggableTrack: true}}
                    value={[minRating, maxRating]}
                    step={0.1}
                    min={min}
                    max={max}
                    onChange={changeRange}
            />
        </div>

        {!isMobile && <>
            <div className="range-inputs">
                <div className="range-input">
                    <p className="range-input-title">{t("searchpage.filters.min")}</p>
                    <InputNumber step={0.1}
                                 precision={2}
                                 addonAfter={<StyledStarIcon/>}
                                 value={minRating}
                                 onChange={changeMinValue}
                    />

                </div>

                <div className="range-input">
                    <p className="range-input-title">{t("searchpage.filters.max")}</p>
                    <InputNumber step={0.1}
                                 precision={2}
                                 addonAfter={<StyledStarIcon/>}
                                 value={maxRating}
                                 onChange={changeMaxValue}
                    />

                </div>
            </div>

            <div className="controls">
                <button data-cy="rating-filter-reset-button" className="reset"
                        onClick={resetRating}>{t("searchpage.reset")}</button>
                <button data-cy="rating-filter-apply-button" className="apply"
                        onClick={() => applyRating()}>{t("searchpage.apply")}</button>
            </div>
        </>}
    </RatingFilterStyled>;
};
