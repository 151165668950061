import {useEffect} from "react";


export const useDisableScroll = (condition: boolean): void => {
		useEffect(() => {
				document.body.style.overflow = condition ? "hidden" : "auto";

				document.body.style.width = condition ? "100%" : "unset";

				return () => {
						document.body.style.overflow = "auto";

						document.body.style.width = "unset";
				};
		}, [condition]);
};