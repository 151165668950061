import styled from "styled-components";


export const NotificationWrapper = styled.div`
  max-width: 450px;
  padding: 0 20px;

  .divider {
    margin: 0;
  }

  .notification {
    padding: 20px 0;
    display: flex;
    column-gap: 16px;

    p {
      margin: 0;
    }
    
    .notification-icon{
      min-width: 56px;
    }

    .notification-body {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 100%;

      .info {
        display: flex;
        justify-content: space-between;

        .main_info {
          display: flex;
          flex-direction: column;
          row-gap: 4px;

          .notification-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }

          .notification-description {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #8C8C8C;
          }

          .notification-date {
            margin-top: 8px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #BFBFBF;
          }
        }

        .check-button {
          .check-circle {
            color: #18067D;
          }
        }
      }

      .notification-button {
        align-self: flex-end;
        border-radius: 8px;
        border: 1px solid #000;
        background: #000;
        padding: 4px 15px;
        color: #FFF;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        cursor: pointer;
        transition: hover, 0.4s;

        &:hover {
          background: #fff;
          color: #000;
        }
      }

    }
  }
`;