import {connect} from "react-redux";

import {SidebarComponent} from "./sidebar";
import {RootState} from "../../redux";


const mapStateToProps = (state: RootState) => {
    return {
        registrationStep: state.userStore.registrationStep,
    }
}


export const Sidebar = connect(mapStateToProps)(SidebarComponent);