import {FC, useCallback, useEffect, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";
import classNames from "classnames";

import {DesktopForm, MobileForm} from "./forms";
import {TeachingMeErrorNotification, TeachingMeSuccessNotification} from "../notification";
import {postFeedback} from "../../../api/user-api";
import CookieService, {feedbackKey} from "../../../services/cookie-service";
import {device} from "../../constants";
import {TFeedbackBody} from "../../../types";
import {TType} from "../../../assets/translations";
import {FeedbackButton} from "./feedback.styled";
import {today} from "../../../services/date-service";


interface Props {
    t: TType;
}

export const Feedback: FC<Props> = ({t}) => {

    const isMobile = useMediaQuery({
        query: `(${device.mobileMax})`
    });

    const timerId = useRef<NodeJS.Timer>();

    const [showFeedbackButton, setShowFeedbackButton] = useState<boolean>(false);

    const [showForm, setShowForm] = useState<boolean>(false);

    useEffect(() => {
        const feedbackProcessed = CookieService.get(feedbackKey);

        if (!feedbackProcessed) {
            const minsCount = 5;

            timerId.current = setTimeout(() => {
                setShowFeedbackButton(true);
            }, minsCount * 60000);
        }

        return () => clearTimeout(timerId.current);
    }, []);

    const approveFeedback = (feedback: TFeedbackBody) => {
        postFeedback(feedback)
            .then(() => {
                const expires = new Date(today);
                expires.setMonth(today.getMonth() + 1);

                CookieService.set(feedbackKey, "true", {expires});

                TeachingMeSuccessNotification(t("feedback.success"));
            })
            .catch(() => TeachingMeErrorNotification(t("errors.feedback-failed")))
            .finally(() => {
                setShowForm(false);
                setShowFeedbackButton(false);
            })
    };

    const cancelFeedback = () => {
        setShowForm(false);
        setShowFeedbackButton(false);

        const expires = new Date(today);
        expires.setDate(today.getDate() + 3);

        CookieService.set(feedbackKey, "false", {expires})
    };

    const renderForm = useCallback(() => isMobile ?
            <MobileForm t={t} open={showForm} approveFeedback={approveFeedback} cancelFeedback={cancelFeedback}/>
            : <DesktopForm t={t} open={showForm} approveFeedback={approveFeedback} cancelFeedback={cancelFeedback}/>,
        [isMobile, showForm]);


    return <>
        {renderForm()}

        <FeedbackButton className={classNames({show: showFeedbackButton})} onClick={() => setShowForm(true)}>
            <p>{t("feedback.title")}</p>
        </FeedbackButton>
    </>
};
