import styled, {keyframes} from 'styled-components';


const sideSlideRight = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`

export const FeedbackButton = styled.button`
  background: #000;
  color: #fff;
  position: fixed;
  z-index: 100;
  right: 0;
  top: 50%;
  padding: 0;
  border: none;
  cursor: pointer;
  border-radius: 8px 0 0 8px;
  transition: padding-right 0.3s ease-in-out;
  opacity: 0;
  display: none;

  p {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    margin: 0;
    padding: 10px 5px;
  }

  &:hover {
    padding-right: 10px;
  }

  &.show {
    animation-iteration-count: 1;
    opacity: 1;
    display: block;
    animation: ${sideSlideRight} 0.2s ease-in-out;
  }
`