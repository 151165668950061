import {AUTH_USER, CLEAR_DATA, SET_REGISTRATION_STEP, SET_ROLE, UPDATE_STATE, UPDATE_TOKENS} from "./tokenConstants";
import {Authority} from "../../../../types";


const initialState = {
    token: "",
    refreshToken: "",
    jwtParsed: {},
    isAuthenticated: true,
    registrationStep: null,
    role: Authority.ROLE_STUDENT,
    email: "",
    userAvatar: "",
}

export function tokensStore(state = initialState, action) {
    const actions = {
        [SET_ROLE]: {
            ...state,
            role: action.role
        },
        [SET_REGISTRATION_STEP]: {
            ...state,
            registrationStep: action.registrationStep
        },
        [UPDATE_STATE]: {
            ...state,
            role: action.data?.role,
            email: action.data?.email,
            registrationStep: action.data?.registrationStep ? action.data?.registrationStep : null,
        },
        [UPDATE_TOKENS]: {
            ...state,
            refreshToken: action.refreshToken || state.refreshToken,
            token: action.token || state.token,
            jwtParsed: action.jwtParsed || state.jwtParsed,
        },
        [AUTH_USER]: {
            ...state,
            isAuthenticated: action.isAuthenticated
        },
        [CLEAR_DATA]: {
            ...initialState,
            isAuthenticated: false
        }
    }

    return actions[action.type] || state
}