import {connect} from "react-redux";

import {MobileHeaderComponent} from "./mobile-header";
import {RootState} from "../../../redux";


const mapStateToProps = (state: RootState) => {
    return {
        registrationStep: state.userStore.registrationStep,
    }
}


export const MobileHeader = connect(mapStateToProps)(MobileHeaderComponent);