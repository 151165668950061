import {GET_TOP_CATEGORIES} from "../endpoints";
import axiosClient from "../../config/axiosConfig";
import {TTopCategoriesResponse} from "../../types";


export const getTopCategories = async (languageCode: string): Promise<TTopCategoriesResponse> => {
    return axiosClient.get(GET_TOP_CATEGORIES(), {
        headers: {
            "Accept-Language": languageCode
        }
    });
}

