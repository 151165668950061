import {Modal} from "antd";
import styled from "styled-components";

export const StyledPlaceholderPopup = styled(Modal)`
    
    background-color: rgba(255, 255, 255, 0.0);

    .ant-modal-content {
        background-color: rgba(255, 255, 255, 0.0);
        box-shadow: none;
    }

    .ant-modal-body{
        .sc-fxwrCY.dhcdjw{
            background-color: rgba(255, 255, 255, 0.0);
        }
    }
`