import Cookies from "universal-cookie";
import {CookieSetOptions} from "universal-cookie/cjs/types";


const cookies = new Cookies();

export const sessionIdKey = "SYSTEM_SESSION_ID";

export const feedbackKey = "feedbackProcessed";

export const cookieConsentKey = "CookieConsent";

class CookieService {
    get(key: string) {
        return cookies.get(key);
    }

    set(key: string, value: string, options?: CookieSetOptions) {
        cookies.set(key, value, options);
    }

    remove(key: string, options?: CookieSetOptions) {
        return cookies.remove(key, options);
    }
}

const cookieService = new CookieService();

export default cookieService;