import {connect} from "react-redux";

import {NotificationsDropdownComponent} from "./notifications-dropdown";

const mapStateToProps = (state: any) => {
    return {
        firstName: state.userStore.firstName,
        lastName: state.userStore.lastName,
        email: state.userStore.email,
    }
}

export const NotificationsDropdown = connect(mapStateToProps)(NotificationsDropdownComponent);
