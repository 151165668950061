import {createContext} from "react";

import {Translations} from "./translationsTypes";


export const DEFAULT_VALUE: {
		language: Translations;
		setLanguage: (language: Translations) => void;
} = {
		language: Translations.uk,
		setLanguage: () => {
				return;
		},
};
export const TranslationsContext = createContext(DEFAULT_VALUE);
