import {REMOVE_USER_STATE, SET_FORGOT_PASSWORD_EMAIL, UPDATE_USER_EMAIL, UPDATE_USER_STATE} from "./userConstants";
import {TUserProfileResponse} from "../../../../types";


export function updateUserInfo(userInfo: TUserProfileResponse) {
		return {
				type: UPDATE_USER_STATE,
				data: userInfo.data,
		};
}

export function removeUserInfo() {
		return {
				type: REMOVE_USER_STATE,
		};
}

export function updateUserEmail(email: string = "") {
		return {
				type: UPDATE_USER_EMAIL,
				email,
		};
}

export function setForgotPasswordEmail(email: string) {
		return {
				type: SET_FORGOT_PASSWORD_EMAIL,
				email,
		};
}