import styled from "styled-components";

import {device} from "../../../../../constants";
import {DollarSignIcon} from "../../../../../../assets/images/svg-elements/dollar-sign";
import {HryvniaSignIcon} from "../../../../../../assets/images/svg-elements/hryvnia-sign";


export const PriceFilterStyled = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  > h2 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    width: fit-content;
  }

  .slider {
    display: flex;
    flex-direction: column;

    .values {
      display: flex;
      justify-content: space-between;

      > p {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
      }
    }
  }

  .range-inputs {
    display: flex;
    column-gap: 16px;
    width: 250px;

    .range-input {
      width: 50%;
    }

    .range-input-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #BFBFBF;
      margin: 0;
    }
  }

  .controls {
    display: flex;
    column-gap: 16px;
    margin-top: 16px;

    > button {
      width: 50%;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      padding: 6.4px 15px;
      cursor: pointer;

      &.apply {
        border-radius: 6px;
        border: 1px solid #18067D;
        background: #18067D;
        box-shadow: 0 4px 60px 0 rgba(24, 6, 125, 0.12);
        color: #fff;
      }

      &.reset {
        color: #18067D;
        border: none;
        background: transparent;
      }
    }
  }

  @media (${device.mobileMax}) {
    padding: 0;
  }
`;


export const StyledDollarSignIcon = styled(DollarSignIcon)`
  margin-top: 5px;
`;

export const StyledHryvniaSignIcon = styled(HryvniaSignIcon)`
    margin-top: 5px;
`
