import {BrowserRouter} from "react-router-dom";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {GoogleOAuthProvider} from "@react-oauth/google";

import AppProviders from "./config/appProviders";
import ApplicationRoutes from "./config/ApplicationRoutes";
import {CriticalStyles} from "./config";
import {useEffect} from "react";
import ReactPixel from 'react-facebook-pixel';
import {clarity} from "react-microsoft-clarity";


const clientId: string = process.env.REACT_APP_GOOGLE_SSO_CLIEND_ID as string;
const enablePixel: boolean = process.env.REACT_APP_ENABLE_PIXEL === "true";
const clarityId: string = process.env.REACT_APP_ENABLE_CLARITY_ID as string;

function App() {
	useEffect(() => {
		if (enablePixel) {
			ReactPixel.init('937141804948508');
			ReactPixel.pageView();
		}
		if(clarityId) {
			clarity.init(clarityId);
		}
	}, []);

		return <BrowserRouter>
				<GoogleOAuthProvider clientId={clientId}>
						<AppProviders>
								<CriticalStyles/>
								<ApplicationRoutes/>
								<ReactQueryDevtools/>
						</AppProviders>
				</GoogleOAuthProvider>
		</BrowserRouter>;
}

export default App;