import {ChangeEvent, FC, useCallback, useEffect, useState} from "react";
import {Divider, Rate} from "antd";

import {ButtonTheme, StyledButton} from "../../button";
import {TType} from "../../../../assets/translations";
import {TFeedbackBody} from "../../../../types";
import {initialFeedback} from "../feedback.types";
import {StyledClose, StyledForm, StyledInput, StyledStar, StyledTextArea} from "./shared-form.styled";
import {validateEmail} from "../../../../services/user-service";


interface Props {
    t: TType;
    approveFeedback: (feedback: TFeedbackBody) => void;
    cancelFeedback: () => void;
    email: string;
}

export const FormComponent: FC<Props> = ({t, approveFeedback, cancelFeedback, email}) => {

    const [feedback, setFeedback] = useState<TFeedbackBody>({...initialFeedback});

    const [emailValidationMessage, setEmailValidationMessage] = useState<string>("")

    const [canSendFeedback, setCanSendFeedback] = useState<boolean>(true);

    const setRating = useCallback((rating: number) => setFeedback({
        ...feedback,
        rating
    }), [feedback]);

    const setComment = useCallback((e: any) => setFeedback({
        ...feedback,
        comment: e.target.value
    }), [feedback]);

    const setEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => setFeedback({
        ...feedback,
        email: e.target.value
    }), [feedback]);

    const prefillEmail = useCallback(() => {
        if (email.length > 0) {
            setFeedback({...feedback, email})
        }
    }, [email, feedback]);

    useEffect(() => {
        prefillEmail();
    }, []);

    useEffect(() => setCanSendFeedback(!!(feedback.comment && feedback.rating && feedback.email)), [feedback])


    return <StyledForm>
        <div className="header">
            <h2>{t("feedback.title")}</h2>
            <StyledClose onClick={cancelFeedback}/>
        </div>
        <div className="form">
            <div className="info"><h4>{t("feedback.caption")}</h4></div>
            {email.length <= 0 && <div className="email">
                <h4>{t("feedback.email")}</h4>
                <StyledInput placeholder={t("email-template")}
                             value={feedback.email}
                             onChange={setEmail}
                             size={"large"}
                             onBlur={e => setEmailValidationMessage(validateEmail(e.target.value, t))}
                             status={emailValidationMessage ? "error" : ""}
                             allowClear
                />
                <p className="error">{emailValidationMessage}</p>
            </div>}
            <div className="comment">
                <h4>{t("feedback.comment")}</h4>
                <StyledTextArea value={feedback.comment}
                                onChange={setComment}
                                placeholder={t("feedback.comment.placeholder")}
                                autoSize={{minRows: 3, maxRows: 5}}
                />
            </div>
            <div className="rating">
                <h4>{t("feedback.rating")}</h4>
                <Rate character={<StyledStar/>}
                      value={feedback.rating}
                      onChange={setRating}/>
            </div>
        </div>
        <Divider style={{margin: "auto 0 0 0"}}/>
        <div className="control_buttons">
            <StyledButton theme={ButtonTheme.WHITE} onClick={cancelFeedback}>
                {t("feedback.cancel")}
            </StyledButton>
            <StyledButton onClick={() => approveFeedback(feedback)} disabled={!canSendFeedback}>
                {t("feedback.send")}
            </StyledButton>
        </div>
    </StyledForm>
};
