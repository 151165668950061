import {FC, memo} from "react";
import {Affix} from "antd";
import {UserOutlined} from "@ant-design/icons";

import {NotificationsDropdown} from "../desktop-header/notifications-dropdown";
import {LanguageSwitch} from "../../language-switch";
import {Sidebar} from "../../sidebar";
import {Authority, StepIds} from "../../../../types";
import {Translations, TType} from "../../../../assets/translations";
import {StyledLogo, StyledOpenMenu, StyledTabletHeader} from "./tablet-header.styled";
import {Underline} from "../../../../assets/images/svg-elements/underline";


interface Props {
    t: TType;
    avatarId: string;
    logOut: () => void;
    firstName: string;
    lastName: string;
    notificationsCount: number;
    setNotificationsCount: any;
    messagesCount: number;
    setMessagesCount: any;
    language: Translations;
    onLanguageChange: (value: string) => void;
    goHome: () => void;
    signIn: () => void;
    signUp: () => void;
    openProfilePage: () => void;
    authority: Authority;
    isAuthenticated: () => boolean;
    renderRegistrationThirdStepPopup: any;
    renderLoginPopup: any;
    renderSignUpPopup: any;
    menuOpen: boolean;
    handleMenuOpen: () => void;
}

export const TabletHeader: FC<Props> = memo(({
                                                 t,
                                                 avatarId,
                                                 logOut,
                                                 firstName,
                                                 lastName,
                                                 notificationsCount,
                                                 setNotificationsCount,
                                                 messagesCount,
                                                 setMessagesCount,
                                                 language,
                                                 onLanguageChange,
                                                 goHome,
                                                 signIn,
                                                 signUp,
                                                 openProfilePage,
                                                 authority,
                                                 isAuthenticated,
                                                 renderRegistrationThirdStepPopup,
                                                 renderLoginPopup,
                                                 renderSignUpPopup,
                                                 menuOpen,
                                                 handleMenuOpen
                                             }) => {

    return <Affix>
        <StyledTabletHeader>
            <div style={{position: "absolute"}}>

                {renderRegistrationThirdStepPopup()}
                {renderLoginPopup()}
                {renderSignUpPopup()}
            </div>

            <div className="header-logo" data-cy="header-logo" onClick={goHome}>
                <StyledLogo/>
                <h4>{t("teaching-me")}
                    <Underline/>
                </h4>
            </div>

            <LanguageSwitch t={t}
                            language={language}
                            onLanguageChange={onLanguageChange}
                            style={{marginLeft: "auto"}}/>
            {isAuthenticated() ?
                <div className="menu">
                    <NotificationsDropdown t={t}
                                           notificationsCount={notificationsCount}
                                           setNotificationsCount={setNotificationsCount}
                                           messagesCount={messagesCount}
                                           setMessagesCount={setMessagesCount}
                    />
                    <StyledOpenMenu data-cy="burger-menu" id={StepIds.menu} onClick={handleMenuOpen}/>
                    <Sidebar t={t}
                             menuOpen={menuOpen}
                             handleMenuOpen={handleMenuOpen}
                             authority={authority}
                             firstName={firstName}
                             lastName={lastName}
                             avatarId={avatarId}
                             notificationsCount={notificationsCount}
                             messagesCount={messagesCount}
                             openProfilePage={openProfilePage}
                             logOut={logOut}/>
                </div>
                :
                <>
                    <div className="login-buttons">
                        <button onClick={signIn} data-cy="login-btn"><UserOutlined/>{t("login.form.button.signin")}
                        </button>
                        <button onClick={signUp} data-cy="sign-up-header-btn">{t("register.createAccount")}</button>
                    </div>
                </>
            }
        </StyledTabletHeader>
    </Affix>
});