import {connect} from "react-redux";

import {ProfileDropdownComponent} from "./profile-dropdown";

const mapStateToProps = (state: any) => {
    return {
        firstName: state.userStore.firstName,
        lastName: state.userStore.lastName
    }
}

export const ProfileDropdown = connect(mapStateToProps)(ProfileDropdownComponent);
