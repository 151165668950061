import {FC, ReactElement, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useMediaQuery} from "react-responsive";

import {ErrorPage, ErrorPageTypes} from "../components/pages";
import {FILL_LOGIN_INFO, HIDE_LOG_IN_MODAL, SHOW_LOG_IN_MODAL} from "../components/redux";
import {device} from "../components/constants";
import {TType} from "../assets/translations";


interface Props {
    isAuthenticated: boolean
    children: ReactElement | null;
    t: TType;
}

export const SecureRoute: FC<Props> = ({isAuthenticated, children, t}) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const isMobile = useMediaQuery({
        query: `(${device.mobileMax})`
    });

    const {pathname, state} = useLocation();

    const login = () => {
        if (isMobile) {
            dispatch({
                type: FILL_LOGIN_INFO,
                loginText: t("login-text.default"),
                callbackAfterLogin: () => navigate(pathname, {state})
            });

            return isMobile && navigate("/login-mobile");
        }

        dispatch({
            type: SHOW_LOG_IN_MODAL,
            loginText: t("login-text.default"),
            callbackAfterLogin: () => navigate(pathname, {state})
        });
    }

    useEffect(() => {
        isAuthenticated ? dispatch({type: HIDE_LOG_IN_MODAL}) : login();
    }, [isAuthenticated]);


    if (!isAuthenticated) {
        return <ErrorPage t={t} type={ErrorPageTypes.ACCESS_DENIED}/>;
    }

    return children;
}
