import styled from "styled-components";
import {Input} from "antd";

import {device} from "../../constants";


export const FooterWrapper = styled.div`
  background-color: #0E044B;

  p {
    margin: 0;
  }

  .policy {
    background: #000;
    padding: 24px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 24px;

    > p {
      color: #BFBFBF;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }

    .media {
      display: flex;
      column-gap: 16px;
      margin-right: auto;
    }

    .policy_terms {
      display: flex;
      column-gap: 16px;

      > div {
        display: flex;
        column-gap: 4px;
        align-items: center;
      }

      p {
        cursor: pointer;
        color: #8C83BE;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .footer {
    padding: 72px 80px 48px;
    display: flex;
    column-gap: 20px;


    .logo-container {
      width: 15%;
      min-width: 197px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 8px;
      cursor: pointer;

      > svg {
        height: 40px;
        width: 36px;
      }

      .logo-text {
        color: #FFF;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      h4 {
        color: #8C83BE;
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      p, a {
        color: #8C83BE;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
          cursor: pointer;
      }
    }

    .social-media-container {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      padding-right: 45px;

      > p {
        color: #BFBFBF;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      .media {
        display: flex;
        column-gap: 16px;
      }
    }

    .follow-us {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      flex-grow: 1;

      > p {
        color: #BFBFBF;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      .input {
        display: flex;
        column-gap: 16px;
        row-gap: 16px;

        button {
          border-radius: 8px;
          cursor: pointer;
          border: none;
          background: #FFF;
          box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          color: #0E044B;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

        }
      }
    }
  }
    
    @media (min-width: 1280px) {
        .footer {
            .menu {
                padding-right: 50px;
            }
        }
    }
    
    @media (min-width: 1024px) and (max-width:1280px) {
        .footer {
            .menu {
                padding-right: 10px;
            }
        }
    }
    
  @media (${device.tabletMax}) {

    .footer {
      padding: 24px;
      column-gap: 24px;
      flex-wrap: wrap;
      row-gap: 24px;
        justify-content: space-between;

      .logo-container {
        min-width: 158px;
      }

      .menu {
        width: 22.5%;
      }

      .social-media-container {
        padding: 0;
      }

      .follow-us {
        width: 100%;
      }
    }

    .policy {
      padding: 24px;
    }
  }

  @media (${device.mobileMax}) {

    .footer {
      .social-media-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        order: -1;
        width: 100%;
      }

      .menu {
        width: 44%;
      }

      .follow-us {
        .input {
          flex-direction: column;
        }
      }
    }

    .policy {
      padding: 16px;

      .media {
        display: none;
      }

      span {
        color: #8C8C8C;
      }
    }
  }
`;

export const SocialMediaButton = styled.div`
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: hover, 0.4s;


  svg {
    color: #0E044B;
    width: 22px;
    height: 22px;
  }

  &:hover {
    background-color: #8C83BE;
  }

`;

export const StyledSubscribeInput = styled(Input)`
  background: #FFF;
  color: rgba(0, 0, 0, 0.85);
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  min-width: 150px;
  
  @media (${device.tabletMax}) {
    min-width: auto;
  }
`;