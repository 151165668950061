import {ChangeEvent, FC, lazy, Suspense, useCallback} from "react";
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {DesktopHeader} from "./desktop-header";
import {TabletHeader} from "./tablet-header";
import {MobileHeader} from "./mobile-header";
import {Filter, TagsRemoveMethodsBindings} from "../../pages";
import {device} from "../../constants";
import {RootState} from "../../redux";
import {setState} from "../../../types";
import {LANGUAGES_LS_KEY, Translations, TType, useTranslate} from "../../../assets/translations";
import {PlaceholderPopup} from "../../modals/placeholder-popup";
import {sendAnalyticEvent} from "../../../services/user-service";
import {saveToLs} from "../../../services/storage-service";

const SignUpChooseRolePopup = lazy(()=>import("../../modals/sign-up-choose-role-popup").then(module=>({default:module.SignUpChooseRolePopup})));
const LoginPopup = lazy(()=>import("../../modals/login-popup").then(module=>({default:module.LoginPopup})));
const RegistrationThirdStepPopup = lazy(()=>import("../../modals/registration-third-step-popup").then(module=>({default:module.RegistrationThirdStepPopup})));

interface Props {
		t: TType;
		avatarId: string;
		setIsAuthenticated: (isAuthenticated: boolean) => void;
		logOut: () => void;
		notificationsCount: number;
		setNotificationsCount: setState<number>;
		messagesCount: number;
		setMessagesCount: setState<number>;
		accessToken: string;
		isShowChooseRole: boolean;
		isShowSignIn: boolean;
		isShowSignUpModal: boolean;
		isShowConfirmEmailModal: boolean;
		isShowRegistrationThirdStepModal: boolean;
		firstName: string;
		lastName: string;
		showLogInModal: () => void;
		showChooseRoleModal: () => void;
		isTeacher: boolean;
		searchDrawerOpen: boolean;
		setSearchDrawerOpen: setState<boolean>;
		filterData: Filter;
		setFilterData: setState<Filter>;
		searchStatus: boolean;
		tags: TagsRemoveMethodsBindings[];
		setTags: setState<TagsRemoveMethodsBindings[]>;
		performSearch: () => void;
		clearAll: () => void;
		applySearchText: (event: ChangeEvent<HTMLInputElement>) => void;
		profileDropdownOpen: boolean;
		handleProfileDropdownOpen: () => void;
		menuOpen: boolean;
		handleMenuOpen: () => void;
		resetRun: (run: boolean) => void;
		setShowRegistration: setState<boolean>;
}

export const TeachingMeHeaderComponent: FC<Props> = ({
																												 t,
																												 avatarId,
																												 setIsAuthenticated,
																												 logOut,
																												 notificationsCount,
																												 setNotificationsCount,
																												 messagesCount,
																												 setMessagesCount,
																												 accessToken,
																												 isShowChooseRole,
																												 isShowSignIn,
																												 isShowSignUpModal,
																												 isShowConfirmEmailModal,
																												 isShowRegistrationThirdStepModal,
																												 firstName,
																												 lastName,
																												 showLogInModal,
																												 showChooseRoleModal,
																												 isTeacher,
																												 searchDrawerOpen,
																												 setSearchDrawerOpen,
																												 filterData,
																												 setFilterData,
																												 searchStatus,
																												 tags,
																												 setTags,
																												 performSearch,
																												 clearAll,
																												 applySearchText,
																												 profileDropdownOpen,
																												 handleProfileDropdownOpen,
																												 menuOpen,
																												 handleMenuOpen,
																												 resetRun,
																												 setShowRegistration,
																										 }) => {


		const navigate = useNavigate();

		const isTablet = useMediaQuery({
				query: `(${device.tabletMin}) and (${device.tabletMax})`,
		});

		const isDesktop = useMediaQuery({
				query: `(${device.desktop})`,
		});

		const isMobile = useMediaQuery({
				query: `(${device.mobileMax})`,
		});

		const {jwtParsed: {authority}} = useSelector((state: RootState) => state.tokensStore);

		const isAuthenticated = (): boolean => !!accessToken?.length;

		const signIn = useCallback(() => {
				sendAnalyticEvent({
						action: "login_btn_click",
						category: "user actions",
				});

				isMobile ? navigate("/login-mobile") : showLogInModal();

		}, [isMobile]);

		const signUp = useCallback(() => {
				sendAnalyticEvent({
						category: "user actions",
						action: "signin_btn_click",
				});

				isMobile ? navigate("/signup-mobile-choose-role") : showChooseRoleModal();
		}, [isMobile]);

		const goHome = useCallback(() => navigate(isTeacher ? "/user/profile" : "/"), [isTeacher]);

		const {language, setLanguage} = useTranslate();

		const onLanguageChange = useCallback((value: string) => {
				saveToLs(LANGUAGES_LS_KEY, value);
				setLanguage(value as Translations);
		}, []);

		const openProfilePage = useCallback(() => navigate("/user/profile"), []);

		const renderLoginPopup = useCallback(() => isShowSignIn ?
			<Suspense fallback={<PlaceholderPopup/>}>
				<LoginPopup t={t}
										resetRun={resetRun}
										setIsAuthenticated={setIsAuthenticated}
										setShowRegistration={setShowRegistration}/>
			</Suspense>
			: null, [isShowSignIn]);

		const renderSignUpPopup = useCallback(() => isShowChooseRole || isShowSignUpModal || isShowConfirmEmailModal ?
			<Suspense fallback={<PlaceholderPopup/>}>
				<SignUpChooseRolePopup t={t}
															 resetRun={resetRun}
															 setShowRegistration={setShowRegistration}/>
			</Suspense>
			: null, [isShowChooseRole, isShowSignUpModal, isShowConfirmEmailModal]);

		const renderRegistrationThirdStepPopup = useCallback(() => isShowRegistrationThirdStepModal ?
			<Suspense fallback={<PlaceholderPopup/>}>
				<RegistrationThirdStepPopup t={t}
																		resetRun={resetRun}
																		setShowRegistration={setShowRegistration}/>
			</Suspense>
			: null, [isShowRegistrationThirdStepModal]);


		if (isDesktop) {
				return <DesktopHeader t={t}
															avatarId={avatarId}
															notificationsCount={notificationsCount}
															setNotificationsCount={setNotificationsCount}
															messagesCount={messagesCount}
															setMessagesCount={setMessagesCount}
															logOut={logOut}
															language={language}
															onLanguageChange={onLanguageChange}
															goHome={goHome}
															signIn={signIn}
															signUp={signUp}
															authority={authority}
															isAuthenticated={isAuthenticated}
															isTeacher={isTeacher}
															renderLoginPopup={renderLoginPopup}
															renderSignUpPopup={renderSignUpPopup}
															renderRegistrationThirdStepPopup={renderRegistrationThirdStepPopup}
															handleProfileDropdownOpen={handleProfileDropdownOpen}
															profileDropdownOpen={profileDropdownOpen}
				/>;
		}

		if (isTablet) {
				return <TabletHeader t={t}
														 avatarId={avatarId}
														 logOut={logOut}
														 firstName={firstName}
														 lastName={lastName}
														 notificationsCount={notificationsCount}
														 setNotificationsCount={setNotificationsCount}
														 messagesCount={messagesCount}
														 setMessagesCount={setMessagesCount}
														 language={language}
														 onLanguageChange={onLanguageChange}
														 goHome={goHome}
														 signIn={signIn}
														 signUp={signUp}
														 openProfilePage={openProfilePage}
														 authority={authority}
														 isAuthenticated={isAuthenticated}
														 renderLoginPopup={renderLoginPopup}
														 renderSignUpPopup={renderSignUpPopup}
														 renderRegistrationThirdStepPopup={renderRegistrationThirdStepPopup}
														 handleMenuOpen={handleMenuOpen}
														 menuOpen={menuOpen}/>;
		}

		return <MobileHeader t={t}
												 avatarId={avatarId}
												 logOut={logOut}
												 firstName={firstName}
												 lastName={lastName}
												 notificationsCount={notificationsCount}
												 setNotificationsCount={setNotificationsCount}
												 messagesCount={messagesCount}
												 setMessagesCount={setMessagesCount}
												 language={language}
												 onLanguageChange={onLanguageChange}
												 goHome={goHome}
												 signIn={signIn}
												 openProfilePage={openProfilePage}
												 authority={authority}
												 isAuthenticated={isAuthenticated}
												 handleMenuOpen={handleMenuOpen}
												 menuOpen={menuOpen}
												 searchDrawerOpen={searchDrawerOpen}
												 setSearchDrawerOpen={setSearchDrawerOpen}
												 filterData={filterData}
												 setFilterData={setFilterData}
												 searchStatus={searchStatus}
												 tags={tags}
												 setTags={setTags}
												 performSearch={performSearch}
												 clearAll={clearAll}
												 applySearchText={applySearchText}/>;
};