import {Category} from "../../../../types";

export interface Option {
    value: number | string;
    label: string;
    disabled?: boolean;
    children?: Option[];
}

export interface TagsRemoveMethodsBindings {
    id: string;
    title: string;
    removeTagMethod: () => void;
}

export interface Filter {
    categories?: any,
    priceFrom?: number,
    priceTo?: number,
    ratingFrom?: number,
    ratingTo?: number,
    verifiedTeacher?: boolean,
    countries?: number[],
    lessonTypes?: string[],
    searchText: string,
    page: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string;
}

export enum ESortByOptions {
    PRICE_PER_HOUR_ASC = `pricePerHour Asc`,
    PRICE_PER_HOUR_DESC = `pricePerHour Desc`,
    RATING_DESC = "rating Desc"
}

export const initialFilterValue: Filter = {
    page: 0,
    pageSize: 10,
    searchText: "",
    sortBy: ESortByOptions.RATING_DESC,
    sortDirection: "Asc"
}

export interface RadioData {
    value: any,
    name: string
}

export type CategoryMobile = {
    key: string;
    header: string;
    children: Category[];
}
