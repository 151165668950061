import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import Joyride, {ACTIONS, EVENTS} from "react-joyride";

import {TeachingMeWarnNotification} from "../components/elements/notification";
import {device} from "../components/constants";
import {RootState} from "../components/redux";
import {skipTour} from "../api/user-api";
import {Authority, initialState, Placement, Step, StepIds, TState, useStepsResult} from "../types";
import {useTranslate} from "../assets/translations";


export const useSteps = (): useStepsResult => {

    const {t} = useTranslate();

    const isMobile = useMediaQuery({
        query: `(${device.tabletMax})`
    });

    const {jwtParsed: {authority}} = useSelector((state: RootState) => state.tokensStore);

    const [state, setState] = useState<TState>({...initialState});

    const resetRun = useCallback((run: boolean) => setState(prev => ({
        ...prev,
        run
    })), []);

    const resetSteps = useCallback((newSteps: Step[]) => setState(prev => ({
        ...prev,
        steps: [...newSteps]
    })), []);

    const handleProfileDropdownOpen = useCallback(() => {
        setState(prev => ({
            ...prev,
            run: prev.stepIndex === 5 ? false : prev.run,
            profileDropdownOpen: !prev.profileDropdownOpen,
            stepIndex: prev.stepIndex === 5 ? 6 : prev.stepIndex,
        }));
    }, []);

    const handleMenuOpen = useCallback(() => {
        setState(prev => ({
            ...prev,
            run: prev.stepIndex === 1 ? false : prev.run,
            menuOpen: !prev.menuOpen,
            stepIndex: prev.stepIndex === 1 ? 2 : prev.stepIndex,
        }));
    }, []);

    const handleJoyrideCallback = useCallback((data: any) => {
        const {action, index, type} = data;

        if (index === 0) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        if (action === ACTIONS.SKIP && index === 0) {
            document.body.style.overflow = "auto";

            skipTour().catch(() => TeachingMeWarnNotification(t("errors.tour-skip")));
        }

        if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

            if (isMobile) {
                if (!state.menuOpen && index === 1 && action === ACTIONS.NEXT) {
                    setState(prev => ({
                        ...prev,
                        run: false,
                        menuOpen: true,
                        stepIndex: nextStepIndex,
                    }));

                    setTimeout(() => resetRun(true), 400);
                } else if (index === 1 && action === ACTIONS.PREV) {
                    setState(prev => ({
                        ...prev,
                        stepIndex: nextStepIndex,
                    }));

                } else if (index === 1) {
                    setState(prev => ({
                        ...prev,
                        run: false,
                        stepIndex: nextStepIndex,
                    }));

                    setTimeout(() => resetRun(true), 400);
                } else if (state.menuOpen && index === 2 && action === ACTIONS.PREV) {
                    setState(prev => ({
                        ...prev,
                        menuOpen: false,
                        stepIndex: nextStepIndex,
                    }));
                } else {
                    setState(prev => ({
                        ...prev,
                        stepIndex: nextStepIndex,
                    }));
                }
            } else {
                if (!state.profileDropdownOpen && index === 5 && action === ACTIONS.NEXT) {
                    setState(prev => ({
                        ...prev,
                        run: false,
                        profileDropdownOpen: true,
                        stepIndex: nextStepIndex,
                    }));

                    setTimeout(() => resetRun(true), 400);
                } else if (index === 5 && action === ACTIONS.PREV) {
                    setState(prev => ({
                        ...prev,
                        stepIndex: nextStepIndex,
                    }));

                } else if (index === 5) {
                    setState(prev => ({
                        ...prev,
                        run: false,
                        stepIndex: nextStepIndex,
                    }));

                    setTimeout(() => resetRun(true), 400);
                } else if (state.profileDropdownOpen && index === 6 && action === ACTIONS.PREV) {
                    setState(prev => ({
                        ...prev,
                        profileDropdownOpen: false,
                        stepIndex: nextStepIndex,
                    }));
                } else {
                    setState(prev => ({
                        ...prev,
                        stepIndex: nextStepIndex,
                    }));
                }
            }

            if (index === 7 && action === ACTIONS.NEXT) {
                skipTour().catch(() => TeachingMeWarnNotification(t("errors.tour-skip")));
            }
        }
    }, [isMobile, resetRun, state]);

    useEffect(() => {
        setState({...initialState});

        const isStudent = authority === Authority.ROLE_STUDENT;

        resetSteps([
            {
                title: t("tour.steps.welcome.title"),
                content: t("tour.steps.welcome.content"),
                target: "body",
                placement: "center",
                showSkipButton: true
            },
            ...(isMobile ? [{
                content: t("tour.steps.menu.title"),
                placement: "left" as Placement,
                target: `#${StepIds.menu}`,
                disableBeacon: true,
                disableOverlayClose: true,
                spotlightClicks: true,
                styles: {
                    tooltip: {
                        width: "fit-content"
                    },
                }
            },] : []),
            isStudent ? {
                title: t("tour.steps.favourites.title"),
                content: t("tour.steps.favourites.content"),
                placement: "bottom",
                target: `#${StepIds.favourites}`,
            } : {
                title: t("tour.steps.schedule.title"),
                content: t("tour.steps.schedule.content"),
                placement: "bottom",
                target: `#${StepIds.schedule}`,
            },
            {
                title: t("tour.steps.lessons.title"),
                content: isStudent? t("tour.steps.lessons.content-student") : t("tour.steps.lessons.content-teacher"),
                placement: "bottom",
                target: `#${StepIds.lessons}`,
            },
            {
                title: t("tour.steps.notifications.title"),
                content: t("tour.steps.notifications.content"),
                placement: "bottom",
                target: `#${StepIds.notifications}`,
            },
            {
                title: t("tour.steps.messenger.title"),
                content: isStudent ? t("tour.steps.messenger.content-student") : t("tour.steps.messenger.content-teacher"),
                placement: "bottom",
                target: `#${StepIds.messenger}`,
            },
            ...(isMobile ? [] : [{
                title: t("tour.steps.profile.title"),
                content: t("tour.steps.profile.content"),
                placement: "bottom" as Placement,
                target: `#${StepIds.avatar}`,
                disableBeacon: true,
                disableOverlayClose: true,
                spotlightClicks: true,
            }]),
            {
                title: t("tour.steps.settings.title"),
                content: isStudent? t("tour.steps.settings.content-student") : t("tour.steps.settings.content-teacher"),
                placement: "left",
                target: `#${StepIds.settings}`,
            },
            {
                title: t("tour.steps.support.title"),
                content: t("tour.steps.support.content"),
                placement: "left",
                target: `#${StepIds.support}`,
            },
        ]);

    }, [authority, isMobile]);


    return {
        joyrideComponent:
            <Joyride run={state.run}
                     callback={handleJoyrideCallback}
                     stepIndex={state.stepIndex}
                     locale={{
                         back: t("tour.back"),
                         close: t("tour.close"),
                         last: t("tour.last"),
                         next: t("tour.next"),
                         open: t("tour.open"),
                         skip: t("tour.skip"),
                     }}
                     styles={{
                         tooltip: {
                             background: "#fff",
                             borderRadius: "12px",
                             padding: "24px 16px",
                         },
                         tooltipTitle: {
                             fontSize: "24px",
                             lineHeight: "32px",
                             fontWeight: 500,
                             color: "#18067D"
                         },
                         tooltipContent: {
                             fontSize: "16px",
                             lineHeight: "24px",
                             fontWeight: 400,
                         },
                         buttonNext: {
                             background: "rgb(13, 13, 13)",
                             borderRadius: "8px",
                             padding: "12px 16px",
                             color: "#fff",
                             boxShadow: "rgba(0, 0, 0, 0.016) 0 2px 0"
                         },
                         buttonBack: {
                             color: "rgb(13, 13, 13)"
                         }
                     }}
                     steps={state.steps}
                     showProgress
                     continuous
                     disableOverlayClose
                     disableScrollParentFix
                     scrollToFirstStep
                     hideCloseButton
                     disableCloseOnEsc
            />,
        handleProfileDropdownOpen,
        profileDropdownOpen: state.profileDropdownOpen,
        handleMenuOpen,
        menuOpen: state.menuOpen,
        run: state.run,
        resetRun
    }
};