import styled from "styled-components";
import {Layout} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import {LogoIcon} from "../../../../assets/images/svg-elements/logo";


const {Header} = Layout;

export const StyledTabletHeader = styled(Header)`
  width: 100%;
  background-color: @layout-body-background;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 24px;
  padding: 14px 24px;

  .header-logo {
    display: flex;
    column-gap: 8px;
    align-items: center;
    cursor: pointer;

    h4 {
      margin: 0;
      display: flex;
      flex-direction: column;
      color: #18067D;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .login-buttons {
    display: flex;
    column-gap: 16px;

    button {
      display: flex;
      column-gap: 16px;
      align-items: center;
      justify-content: center;
      padding: 6px 15px;
      border-radius: 36px;
      border: 1px solid #D9D9D9;
      background: #FFF;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      transition: hover, 0.4s;
      cursor: pointer;

      &:hover {
        border-color: #000;
      }

    }
  }

`;


export const StyledLogo = styled(LogoIcon)`
  height: 32px;
  width: 28px;
`

export const StyledOpenMenu = styled(MenuOutlined)`
  cursor: pointer;
`