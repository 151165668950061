import {useContext} from "react";
import {useQuery} from "@tanstack/react-query";

import {QUERY_KEYS} from "../../config";
import {getTopCategories} from "../../api/category-api";
import {TranslationsContext} from "../../assets/translations/translationsContext";


export const useTopCategories = () => {

    const context = useContext(TranslationsContext);

    return useQuery({
        queryKey: QUERY_KEYS.CATEGORIES.all(),
        queryFn: () => getTopCategories(context.language).then(({data}) => data),
    });
}