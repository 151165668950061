import styled from "styled-components";
import {Layout} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import {LogoIcon} from "../../../../assets/images/svg-elements/logo";


const {Header} = Layout;

export const StyledMobileHeader = styled(Header)`
  width: 100%;
  background-color: @layout-body-background;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  padding: 14px;

  .header-logo {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    color: #18067D;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }

  .search-button {
    border-radius: 32px;
    background: #2F1F8A;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    column-gap: 8px;
    color: #FAFAFA;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;

    span {
      font-size: 16px;
    }

    &.searchModeActive {
      background: #FAFAFA;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .menu {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .login-button {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border-radius: 36px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
    padding: 4px 12px;
    transition: hover, 0.4s;
    cursor: pointer;

    &:hover {
      border-color: #000;
    }

  }

  &.disabled {
    .header-logo {
      cursor: not-allowed;

      svg {
        pointer-events: none;
      }
    }
    
    span:has(.search-button) {
      cursor: not-allowed;

      .search-button {
        pointer-events: none;
      }
    }

  }
`;


export const StyledOpenMenu = styled(MenuOutlined)`
  cursor: pointer;
`

export const StyledLogo = styled(LogoIcon)`
  height: 32px;
  width: 28px;
`