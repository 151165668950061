import {FC, ReactElement} from "react";
import {Navigate} from "react-router-dom";
import {useMediaQuery} from "react-responsive";

import {device} from "../components/constants";


interface Props {
    children: ReactElement | null;
    enableOnTablet?: boolean;
}

export const MobileSecureRoute: FC<Props> = ({children, enableOnTablet = false}) => {

    const isMobile = useMediaQuery({
        query: `(${enableOnTablet ? device.tabletMax : device.mobileMax})`
    });


    if (!isMobile) {
        return <Navigate to="/"/>;
    }

    return children;
}
