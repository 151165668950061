import {AUTH_USER, CLEAR_DATA, SET_REGISTRATION_STEP, SET_ROLE, UPDATE_STATE, UPDATE_TOKENS} from "./tokenConstants";
import {RegistrationStep, TTokenUpdateBody, TUserAuthenticationResponse} from "../../../../types";


export function setRoleAction(role: string) {
    return {
        type: SET_ROLE,
        role
    }
}

export function updateTokenState(authResponse: TUserAuthenticationResponse) {
    return {
        type: UPDATE_STATE,
        data: authResponse.data
    }
}

export function updateTokens({token, refreshToken, jwtParsed}: TTokenUpdateBody) {
    return {
        type: UPDATE_TOKENS,
        token,
        refreshToken,
        jwtParsed
    }
}

export function authUser(isAuthenticated: boolean) {
    return {
        type: AUTH_USER,
        isAuthenticated,
    }
}

export function clearUsersData() {
    return {
        type: CLEAR_DATA,
    }
}

export function setRegistrationStepAction(registrationStep: RegistrationStep | null) {
    return {
        type: SET_REGISTRATION_STEP,
        registrationStep: registrationStep
    }
}
