import styled from "styled-components";

interface StyledProps {
  wrapperHeight?: string;
  loaderSize?:string;
}

export const CustomLoaderStyled = styled.div<StyledProps>`
    --spinner-size: ${({loaderSize}) => loaderSize};
    
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: ${({wrapperHeight}) => wrapperHeight};

    .spinner {
        display: flex;
        justify-content: center;
        flex: 1;
    }

    .spinner-circle {
        --spinner-border-width: calc(var(--spinner-size) * 0.0488);
        width:var(--spinner-size);
        height: var(--spinner-size);
        border-radius: 50%;
        border: var(--spinner-border-width) solid rgba(255, 255, 255, 0);
        border-top-color: #18067D;
        animation: spin 0.95s linear infinite;
    }
    
    .spinner-circle.spinner-circle-small {
        --spinner-border-width: calc(var(--spinner-size) * 0.07);
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

export const RoutesCustomLoaderWrapper = styled.div`
    min-height: 100vh;
    display: flex;
`