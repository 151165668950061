import styled from "styled-components";


export const ProfileDropdownWrapper = styled.div`
  box-shadow: 0 14px 75px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  .profile {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #FAFAFA;
    padding: 12px;
    align-items: center;
    column-gap: 12px;

    .profile-info-container {
      display: flex;
      flex-direction: column;
      max-width: 150px;
      overflow-wrap: anywhere;

      .profile-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }

      .view-profile {
        color: #5D51A4;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }

  }

  .profile-menu {
    border-top: 1px solid #FAFAFA;
    padding: 8px 0;
    display: flex;
    flex-direction: column;

    .profile-menu-button {
      display: flex;
      flex-direction: row;
      padding: 5px 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      cursor: pointer;

      :hover {
        background-color: #fafafa;
      }
    }

    .profile-menu-button-sign-out {
      color: red;
    }

    .profile-menu-button-icon {
      height: 12px;
      width: 12px;
      margin-top: 4px;
      margin-right: 8px;
    }
  }
`;

export const StyledAvatarImg = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;
