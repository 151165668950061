import {ChangeEvent, FC} from "react";
import classNames from "classnames";
import {Affix} from "antd";
import {SearchOutlined} from "@ant-design/icons";

import {NotificationsDropdown} from "../desktop-header/notifications-dropdown";
import {LanguageSwitch} from "../../language-switch";
import {Sidebar} from "../../sidebar";
import {Hint} from "../../hint";
import {Filter, TagsRemoveMethodsBindings} from "../../../pages";
import {MobileSearchFilter} from "../../../pages/mobile";
import {Authority, setState, StepIds} from "../../../../types";
import {Translations, TType} from "../../../../assets/translations";
import {StyledLogo, StyledMobileHeader, StyledOpenMenu} from "./mobile-header.styled";


interface Props {
    t: TType;
    avatarId: string;
    logOut: any;
    firstName: string;
    lastName: string;
    notificationsCount: number;
    setNotificationsCount: setState<number>;
    messagesCount: number;
    setMessagesCount: setState<number>;
    language: Translations;
    onLanguageChange: (value: string) => void;
    goHome: () => void;
    signIn: () => void;
    openProfilePage: () => void;
    authority: Authority;
    isAuthenticated: () => boolean;
    searchDrawerOpen: boolean;
    setSearchDrawerOpen: any;
    filterData: Filter;
    setFilterData: setState<Filter>;
    searchStatus: boolean;
    tags: TagsRemoveMethodsBindings[];
    setTags: setState<TagsRemoveMethodsBindings[]>;
    performSearch: () => void;
    clearAll: () => void;
    applySearchText: (event: ChangeEvent<HTMLInputElement>) => void;
    menuOpen: boolean;
    handleMenuOpen: () => void;
    registrationStep: number | null;
}

export const MobileHeaderComponent: FC<Props> = ({
                                                     t,
                                                     avatarId,
                                                     logOut,
                                                     firstName,
                                                     lastName,
                                                     notificationsCount,
                                                     setNotificationsCount,
                                                     messagesCount,
                                                     setMessagesCount,
                                                     language,
                                                     onLanguageChange,
                                                     goHome,
                                                     signIn,
                                                     openProfilePage,
                                                     authority,
                                                     isAuthenticated,
                                                     searchDrawerOpen,
                                                     setSearchDrawerOpen,
                                                     filterData,
                                                     setFilterData,
                                                     searchStatus,
                                                     tags,
                                                     setTags,
                                                     performSearch,
                                                     clearAll,
                                                     applySearchText,
                                                     menuOpen,
                                                     handleMenuOpen,
                                                     registrationStep
                                                 }) => {

    const isTeacher = authority === Authority.ROLE_TEACHER;

    const openSearchDrawer = () => setSearchDrawerOpen(true);

    const disabledNavigation = registrationStep !== null;


    return <Affix>
        <StyledMobileHeader className={classNames({disabled: disabledNavigation})}>

            <Hint showHint={disabledNavigation} placement={"leftTop"} title={t("registration.disabled-header-hint")}>
                <div className="header-logo" data-cy="header-logo">
                    <StyledLogo onClick={goHome}/>
                </div>
            </Hint>

            <LanguageSwitch t={t} language={language} onLanguageChange={onLanguageChange}/>

            {!isTeacher &&
                <Hint showHint={disabledNavigation}
                      placement="bottom"
                      title={t("registration.disabled-header-hint")}>
                        <span>
                             <div data-cy="search-button-inside-mobile-header"
                                  className={classNames("search-button", {searchModeActive: searchStatus})}
                                  onClick={openSearchDrawer}>
                                <SearchOutlined/>{t("searchpage.search")}
                            </div>
                         </span>
                </Hint>}
            {isAuthenticated() ?
                <div className="menu">
                    <NotificationsDropdown t={t}
                                           renderButtons={false}
                                           notificationsCount={notificationsCount}
                                           setNotificationsCount={setNotificationsCount}
                                           messagesCount={messagesCount}
                                           setMessagesCount={setMessagesCount}
                    />
                    <StyledOpenMenu data-cy="burger-menu" id={StepIds.menu} onClick={handleMenuOpen}/>
                    <Sidebar t={t}
                             handleMenuOpen={handleMenuOpen}
                             menuOpen={menuOpen}
                             authority={authority}
                             firstName={firstName}
                             lastName={lastName}
                             avatarId={avatarId}
                             notificationsCount={notificationsCount}
                             messagesCount={messagesCount}
                             openProfilePage={openProfilePage}
                             logOut={logOut}/>
                </div>
                :
                <>
                    <div className="login-button" onClick={signIn} data-cy="login-btn">
                        {t("login.form.button.signin")}
                    </div>
                </>
            }

            <MobileSearchFilter t={t}
                                setSearchDrawerOpen={setSearchDrawerOpen}
                                searchDrawerOpen={searchDrawerOpen}
                                tags={tags}
                                setTags={setTags}
                                clearAll={clearAll}
                                performSearch={performSearch}
                                filterData={filterData}
                                setFilterData={setFilterData}
                                applySearchText={applySearchText}
            />
        </StyledMobileHeader>
    </Affix>;
};