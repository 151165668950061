import {FC} from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import {EndMessage} from "../../../end-message";
import {NotificationComponent} from "../notification-component";
import {getNotifications, postCheckNotification, postReadAllNotifications} from "../../../../../api/user-api";
import {Notification, setState, TNotificationsResponse} from "../../../../../types";
import {TType, useTranslate} from "../../../../../assets/translations";
import {NotificationsListWrapper} from "./notifications-component.styled";
import {CustomLoader} from "../../../custom-loader";
import {refresh} from "../../../../../services/user-service";


interface Props {
    t: TType,
    notifications: TNotificationsResponse;
    setNotifications: setState<TNotificationsResponse>;
    setNotificationsCount: setState<number>;
    notificationsCount: number;
    getNextPageData: () => void;
    setPage: setState<number>;
}

export const NotificationMessages: FC<Props> = ({
                                                    t,
                                                    notifications,
                                                    setNotifications,
                                                    setNotificationsCount,
                                                    notificationsCount,
                                                    getNextPageData,
                                                    setPage
                                                }) => {

    const {language} = useTranslate();


    const readAll = () => {
        postReadAllNotifications()
            .then(() => getNotifications(language, 0)
                .then((response) => {
                    setNotifications(response);
                    setNotificationsCount(response?.data?.unreadNotifications);

                    setPage(1);
                }));
    }

    const checkNotification = (notification: Notification) => {
        postCheckNotification(notification.id)
            .then(() => {
                notifications.data.notifications.find(n => n.id === notification.id)!.checked = true;

                notifications.data.unreadNotifications = notifications.data.unreadNotifications - 1;
                setNotifications(notifications);
                setNotificationsCount(notificationsCount - 1);
            });
    }

    return <NotificationsListWrapper id="notifications-modal">
        {!!notificationsCount && <p data-cy="read-all-notifications" className="read-all" onClick={readAll}>{t("notifications.mark-as-read")}</p>}
        {notifications.data.notifications.length ?
            <InfiniteScroll children={<>{notifications?.data?.notifications?.map((notification, index) => (
                <NotificationComponent key={Number(notification.id)}
                                       isDividerVisible={index !== 0}
                                       notification={notification}
                                       checkNotification={checkNotification}/>))}</>}
                            dataLength={notifications?.data?.notifications?.length}
                            scrollThreshold={0.8}
                            refreshFunction={refresh}
                            scrollableTarget="notifications-modal"
                            hasMore={!notifications.data.isLast}
                            loader={<CustomLoader wrapperHeight="64px"
                                            loaderSize="32px"/>}
                            endMessage={<EndMessage t={t}/>}
                            pullDownToRefresh
                            next={getNextPageData}
            /> :
            <div style={{padding: "16px"}}>{t("notifications.noNotifications")}</div>}
    </NotificationsListWrapper>
};
