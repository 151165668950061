import {FC, useLayoutEffect, useState} from 'react';
import {Radio, RadioChangeEvent} from 'antd';
import {useMediaQuery} from "react-responsive";

import {device} from "../../../../../constants";
import {Filter, RadioData, TagsRemoveMethodsBindings} from "../../search-input-filter.types";
import {TType} from "../../../../../../assets/translations";
import {setState} from "../../../../../../types";
import {RadioGroup, StatusFilterStyled} from "./status-filter.styled";


interface Props {
    t: TType;
    filterData: Filter;
    setFilterData: setState<Filter>;
    setTags: setState<TagsRemoveMethodsBindings[]>;
    tagName?: string;
}

const getValues = (t: TType): RadioData[] => [
    {
        value: true,
        name: t("searchpage.filters.verified-teachers")
    },
    {
        value: false,
        name: t("searchpage.filters.unverified-teachers")
    }
];

export const StatusFilter: FC<Props> = ({
                                            t,
                                            filterData,
                                            setFilterData,
                                            setTags,
                                            tagName = "verificationStatusTag"
                                        }) => {

    const isMobile = useMediaQuery({
        query: `(${device.mobileMax})`
    });

    const [status, setStatus] = useState<boolean | undefined>(undefined);

    useLayoutEffect(() => {
        setStatus(filterData.verifiedTeacher);
    }, []);

    const onChange = (e: RadioChangeEvent) => {
        setStatus(e.target.value);

        isMobile && applyVerificationStatus(e.target.value);
    };

    const resetVerificationStatus = () => {
        setTags(prev => prev.filter(tag => tag.id !== tagName));
        setFilterData(prev => ({...prev, verifiedTeacher: undefined}));

        setStatus(undefined);
    };

    const applyVerificationStatus = (statusValue = status) => {
        if (statusValue === undefined) {
            return;
        }

        setTags(prev => {
            const tag = prev.find(tag => tag.id === tagName);

            const title = t(`searchpage.filters.${statusValue ? "verified-teachers" : "unverified-teachers"}`);

            if (tag) {
                return [...prev.map(tag => tag.id === tagName ? {
                    ...tag,
                    title,
                    removeTagMethod: resetVerificationStatus
                } : {...tag})];
            } else {
                return [...prev, {id: tagName, title, removeTagMethod: resetVerificationStatus}];
            }
        })


        setFilterData(prev => ({...prev, verifiedTeacher: statusValue}));
    };


    return <StatusFilterStyled>
        {!isMobile && <h2>{t("searchpage.filters.status.title")}</h2>}

        <RadioGroup className="filter-radio-group" value={status} onChange={onChange}>
            {getValues(t).map((entry) => <Radio key={entry.name}
                                                className="filter-radio"
                                                value={entry.value}>
                    {entry.name}
                </Radio>
            )}
        </RadioGroup>

        {!isMobile && <div className="controls">
            <button data-cy="status-filter-reset-button" className="reset"
                    onClick={resetVerificationStatus}>{t("searchpage.reset")}</button>
            <button data-cy="status-filter-apply-button" className="apply"
                    onClick={() => applyVerificationStatus()}>{t("searchpage.apply")}</button>
        </div>}
    </StatusFilterStyled>;
};
