import {connect} from "react-redux";

import {ErrorPageComponent} from "./error-page";
import {Authority} from "../../../types";
import {RootState} from "../../redux";


const mapStateToProps = (state: RootState) => {
    return {
        isTeacher: state.tokensStore.jwtParsed.authority === Authority.ROLE_TEACHER,
    }
}

export const ErrorPage = connect(mapStateToProps)(ErrorPageComponent);

export {ErrorPageTypes} from "./error-page";