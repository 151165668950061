import {connect} from "react-redux";

import {TeachingMeFooterComponent} from "./footer-element";
import {Authority} from "../../../types";
import {RootState, SHOW_CHOOSE_ROLE_MODAL, SHOW_LOG_IN_MODAL} from "../../redux";


const mapStateToProps = (state: RootState) => {
    return {
        isTeacher: state.tokensStore.jwtParsed.authority === Authority.ROLE_TEACHER,
        isStudent: state.tokensStore.jwtParsed.authority === Authority.ROLE_STUDENT,
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    showChooseRoleModal: () => dispatch({type: SHOW_CHOOSE_ROLE_MODAL}),
    showLogInModal: () => dispatch({type: SHOW_LOG_IN_MODAL})
})

export const TeachingMeFooter = connect(mapStateToProps, mapDispatchToProps)(TeachingMeFooterComponent);
