import {Store} from "redux";

import {RootState} from "../components/redux";


let store: Store<RootState>;

export const injectStoreToProvider = (_store: Store<RootState>) => {
    store = _store
};

export const getToken = () => store?.getState().tokensStore.token;

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;
