import {CATEGORIES} from "../endpoints";
import axiosClient from "../../config/axiosConfig";
import {TCategoriesResponse} from "../../types";


export const getCategories = async (languageCode: string): Promise<TCategoriesResponse> => {
    return axiosClient.get(CATEGORIES(), {
        headers: {
            "Accept-Language": languageCode
        }
    });
}

