import {FC, useCallback} from "react";
import {Link} from "react-router-dom";
import {Button, Divider} from "antd";
import {CheckCircleOutlined} from '@ant-design/icons';

import {Notification, NotificationType} from "../../../../../types";
import {useTranslate} from "../../../../../assets/translations";
import {NotificationWrapper} from "./notification-component.styled";
import {ScheduleNotificationIcon} from "../../../../../assets/images/svg-elements/schedule-notification";
import {LessonNotificationIcon} from "../../../../../assets/images/svg-elements/lesson-notification";
import {TransactionNotificationIcon} from "../../../../../assets/images/svg-elements/transaction-notification";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/uk';

dayjs.extend(localizedFormat);

interface Props {
    notification: Notification;
    checkNotification: any;
    isDividerVisible: boolean;
}

export const NotificationComponent: FC<Props> = ({
                                                     notification,
                                                     checkNotification,
                                                     isDividerVisible
                                                 }) => {

    const {language} = useTranslate();

    const getNotificationIcon = useCallback((type: NotificationType) => {
        switch (type) {
            case NotificationType.SCHEDULE_NOTIFICATION:
                return <ScheduleNotificationIcon className="notification-icon"/>;
            case NotificationType.LESSON_NOTIFICATION:
                return <LessonNotificationIcon className="notification-icon"/>;
            case NotificationType.TRANSACTION_NOTIFICATION:
                return <TransactionNotificationIcon className="notification-icon"/>

        }
    }, []);


    return <NotificationWrapper>
        {isDividerVisible && <Divider className="divider"/>}
        <div data-cy="notification-card" className="notification">
            {getNotificationIcon(notification.type)}
            <div className="notification-body">
                <div className="info">
                    <div className="main_info">
                        <p className="notification-title">{notification.title}</p>
                        <p className="notification-description">{notification.description}</p>
                        <p className="notification-date">{dayjs(new Date(notification.createdDate.concat("Z"))).locale(language).format("HH:mm   DD MMM YY")}</p>
                    </div>
                    {!notification.checked && <Button data-cy="read-notification-btn"
                                                      type="ghost"
                                                      onClick={() => checkNotification(notification)}
                                                      className="check-button"
                                                      shape="circle"
                                                      icon={<CheckCircleOutlined className="check-circle"/>}
                                                      size={"middle"}/>}
                </div>
                {notification.actionLink && <Link to={notification.actionLink} className="notification-button">
                    {notification.actionTitle}
                </Link>}
            </div>
        </div>
    </NotificationWrapper>
};
