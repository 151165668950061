import {connect} from "react-redux";

import {PriceFilterComponent} from "./price-filter";

const mapStateToProps = (state: any) => {
  return {
    storedPriceRange: state.userStore.priceRange,
  }
}

export const PriceFilter = connect(mapStateToProps)(PriceFilterComponent);