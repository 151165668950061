import {ComponentType} from "react";

import {useTranslate, WithTranslateProps} from "./useTranslate";


export function withTranslate<T extends WithTranslateProps = WithTranslateProps>(
    Component: ComponentType<T>
) {
    const displayName = Component.displayName || Component.name || "Component";

    const ComponentWithTranslate = (props: Omit<T, keyof WithTranslateProps>) => {
        const {t} = useTranslate();
        const injectedProps = {t};
        return <Component {...injectedProps} {...(props as T)} />;
    };

    ComponentWithTranslate.displayName = `withTranslate(${displayName})`;
    return ComponentWithTranslate;
}
