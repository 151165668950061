import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/uk';

import {ITask, TTaskText, TTimeSlot} from "../types";
import {Translations} from "../assets/translations";

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(timezone);

export const today = new Date();

const dateRange = {
    firstDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    lastDate: new Date(today.getFullYear(), today.getMonth() + 1, new Date(today.getFullYear(), today.getMonth() + 2, 0).getDate(), 23, 59, 59)
}

function ifCurrentHour(hour: Date | string): boolean {
    return new Date(hour).getDate() === today.getDate() && new Date(hour).getHours() === today.getHours()
}

function ifCurrentWeekHour(hour: Date | string): boolean {
    return new Date(hour).getHours() === today.getHours()
}

function isSameDay(day1: Date | string, day2: Date | string): boolean {
    return new Date(day1).getDate() === new Date(day2).getDate();
}

function normalizeDateToShow(date: Date, locale?: Translations): string {
    return date.toLocaleDateString(locale || Translations.en, {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
}

function getWeekNameByNumber(dayNumber: number, locale: Translations): string {
    const date = new Date();
    date.setDate(date.getDate() + dayNumber);

    return date.toLocaleDateString(locale, {weekday: 'long'});
}

function getMonthNameWithDate(date: Date | string | undefined, locale: Translations, shortDate: boolean = false): string {

    const type = shortDate ? "MMM" : "MMMM";
    return dayjs(date).locale(locale).format(`${type}, DD`);
}

function normalizeHourToShow(hour: string): string {
    return dayjs(hour).format("HH:mm");
}

function normalizeHourWithTimezone(hour: string | Date): string {
    return dayjs(new Date(hour)).format("HH:mm");
}

function getFirstWeekDay(date: Date): Date {
    return dayjs(date).startOf("isoWeek").toDate();
}

function getLastWeekDay(date: Date): Date {
    return dayjs(date).endOf("isoWeek").toDate();
}

function getFirstMonthDay(date: Date): Date {
    return dayjs(date).startOf("month").toDate();
}

function getLastMonthDay(date: Date): Date {
    return dayjs(date).endOf("month").toDate();
}

function getHourWithTzOffsetDayjs(hour: string, timeFormat: string): dayjs.Dayjs {
    return dayjs.utc(hour, timeFormat).local();
}

function getHourWithInUtcDayjs(hour: dayjs.Dayjs): string {
    return dayjs.utc(hour).format("HH:mm");
}

function getSimplifiedDate(date: string | Date): string {
    return dayjs.utc(date).format("YYYY-MM-DD HH:mm");
}

function getFormattedTimezone(): string {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));

    const offsetSign = offsetMinutes > 0 ? '-' : '+';

    return `${offsetSign}${offsetHours.toString().padStart(2, '0')}:00`;
}

function getRegionCityFormattedTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function makeCurrentLessonTimeIncludable(currentLessonTime:string):string {
    let date = new Date(currentLessonTime);

    date.setMinutes(date.getMinutes() - 1);
    return date.toISOString();
}

function isPastByDay(date1:Date, date2:Date):boolean {
    if (date1.getFullYear() < date2.getFullYear()) return true;
    if (date1.getFullYear() === date2.getFullYear() && date1.getMonth() < date2.getMonth()) return true;
    if (date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() < date2.getDate()) return true;

    return false;
}

function isPast (date1:Date,date2:Date):boolean {
    return date1 < date2;
}

class Task implements ITask {
    hour: string;
    active?: boolean;
    disabled?: boolean;
    task!: TTaskText | null;

    constructor(hour: string, disabled: boolean = false, active: boolean = false, task: TTaskText | null) {
        this.hour = hour;
        if (task) {
            this.task = task;
        }
        this.disabled = disabled;
        this.active = active;
    }
}

const createEvent = (startDate: string, endDate: string, isRepeatable: boolean = false, multiplier: number = 1): TTimeSlot => ({
    startDate,
    endDate,
    isRepeatable,
    multiplier
});

export {
    dateRange,
    Task,
    ifCurrentHour,
    ifCurrentWeekHour,
    isSameDay,
    normalizeDateToShow,
    normalizeHourToShow,
    getMonthNameWithDate,
    normalizeHourWithTimezone,
    getFirstWeekDay,
    getLastWeekDay,
    getHourWithTzOffsetDayjs,
    getHourWithInUtcDayjs,
    getSimplifiedDate,
    createEvent,
    getFirstMonthDay,
    getLastMonthDay,
    getWeekNameByNumber,
    getFormattedTimezone,
    getRegionCityFormattedTimezone,
    makeCurrentLessonTimeIncludable,
    isPastByDay,
    isPast
};