import styled from "styled-components";

import {device} from "../../../../constants";


export const NotificationsDropdownWrapper = styled.div`
  display: flex;
  column-gap: 20px;

  .ant-avatar {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FAFAFA;
    border-radius: 50%;
    cursor: pointer;
    color: black;

    &:hover {
      box-shadow: 0 0 0 1px #18067D;
    }
  }

  .ant-badge-count {
    z-index: 2;
  }

  @media (${device.tabletMax}) {
    column-gap: 8px;

    .ant-avatar {
      width: 32px;
      height: 32px;

      svg {
        width: 60%;
      }
    }

    .ant-badge-count {
      padding: 0 6px;
    }
  }
`;