import {FC, useState} from 'react';
import {Radio, RadioChangeEvent} from "antd";
import {useMediaQuery} from "react-responsive";

import {device} from "../../../../../constants";
import {Filter, RadioData, TagsRemoveMethodsBindings} from "../../search-input-filter.types";
import {LessonType, setState} from '../../../../../../types';
import {TType} from "../../../../../../assets/translations";
import {AvailabilityFilterStyled, RadioGroup} from "./availability-filter.styled";
import {capitalizeFirstLetter} from "../../../../../../services/teacher-service";


interface Props {
    t: TType;
    filterData: Filter;
    setFilterData: setState<Filter>;
    setTags: setState<TagsRemoveMethodsBindings[]>;
    tagName?: string;
}

const getValues = (t: TType): RadioData[] => [
    {
        value: LessonType.BOTH,
        name: t(`lesson-types.${LessonType.BOTH.toLowerCase()}`)
    },
    {
        value: LessonType.ONLINE,
        name: t(`lesson-types.${LessonType.ONLINE.toLowerCase()}`)
    },
    {
        value: LessonType.OFFLINE,
        name: t(`lesson-types.${LessonType.OFFLINE.toLowerCase()}`)
    }
]

export const AvailabilityFilter: FC<Props> = ({
                                                  t,
                                                  filterData,
                                                  setFilterData,
                                                  setTags,
                                                  tagName = "availabilityStatusTag"
                                              }) => {

    const isMobile = useMediaQuery({
        query: `(${device.mobileMax})`
    });

    const [status, setStatus] = useState<LessonType | undefined>(
        capitalizeFirstLetter(filterData.lessonTypes?.[0] || "") as LessonType || undefined
    );

    const onChange = (e: RadioChangeEvent) => {
        setStatus(e.target.value);

        isMobile && applyAvailabilityStatus(e.target.value);
    };

    const resetAvailabilityStatus = () => {
        setTags(prev => prev.filter(tag => tag.id !== tagName));
        setFilterData(prev => ({...prev, lessonTypes: undefined}));

        setStatus(undefined);
    };

    const applyAvailabilityStatus = (statusValue = status) => {
        if (statusValue === undefined) {
            return;
        }

        setTags(prev => {
            const tag = prev.find(tag => tag.id === tagName);

            if (tag) {
                return [...prev.map(tag => tag.id === tagName ? {
                    ...tag,
                    title: t(`lesson-types.${statusValue.toLowerCase()}`),
                    removeTagMethod: resetAvailabilityStatus
                } : {...tag})];
            } else {
                return [...prev, {
                    id: tagName,
                    title: t(`lesson-types.${statusValue.toLowerCase()}`),
                    removeTagMethod: resetAvailabilityStatus
                }];
            }
        });

        setFilterData(prev => ({...prev, lessonTypes: [statusValue.toUpperCase()]}));
    };


    return <AvailabilityFilterStyled>
        {!isMobile && <h2>{t("searchpage.filters.availability.title")}</h2>}

        <RadioGroup className="filter-radio-group" value={status} onChange={onChange}>
            {getValues(t).map((entry) => <Radio key={entry.name}
                                                className="filter-radio"
                                                value={entry.value}>
                    {entry.name}
                </Radio>
            )}
        </RadioGroup>

        {!isMobile && <div className="controls">
            <button data-cy="availability-filter-reset-button" className="reset"
                    onClick={resetAvailabilityStatus}>{t("searchpage.reset")}</button>
            <button data-cy="availability-filter-apply-button" className="apply"
                    onClick={() => applyAvailabilityStatus()}>{t("searchpage.apply")}</button>
        </div>}
    </AvailabilityFilterStyled>;
};

