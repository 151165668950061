import {Filter} from "../../components/pages";
import {POST_SEARCH_CATEGORIES, POST_SEARCH_CATEGORIES_OPEN} from "../endpoints";
import axiosClient from "../../config/axiosConfig";
import {getToken} from "../tokenHeaderProvider";
import {TCategoriesSearchResponse} from "../../types";
import ReactPixel from "react-facebook-pixel";

const enablePixel: boolean = process.env.REACT_APP_ENABLE_PIXEL === "true";

export const postCategoriesSearch = async (languageCode: string, requestBody: Filter): Promise<TCategoriesSearchResponse> => {

    if (enablePixel) {
        ReactPixel.trackCustom('UseTeacherSearch',
            { categories: requestBody.categories });
    }

    const searchUrl = getToken() ? POST_SEARCH_CATEGORIES() : POST_SEARCH_CATEGORIES_OPEN();

    return axiosClient.post(searchUrl, requestBody, {
        headers: {
            "Accept-Language": languageCode
        }
    });
}
