import styled from 'styled-components';
import {LogoIcon} from "../../../../assets/images/svg-elements/logo";


export const DesktopHeaderComponentWrapper = styled.div`
  padding: 30px 80px;
  display: flex;
  justify-content: center;
  z-index: 10;
  background-color: @layout-body-background;

  .header {
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;

    .header-logo {
      display: flex;
      column-gap: 8px;
      align-items: center;
      cursor: pointer;

      h4 {
        margin: 0;
        color: #0E044B;
        font-size: 18px;
        font-weight: 400;
        line-height: normal;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .header-buttons {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-right: 20px;

    .menu-buttons-wrapper {
      display: flex;
      column-gap: 28px; 
    }

    .menu-button {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      &.active {
        color: #18067D;
        pointer-events: none;
      }

      :hover {
        color: #18067D;
      }
    }

    .not-authorized-buttons-wrapper {
      display: flex;
      column-gap: 24px;

      .login, .create-account {
        display: flex;
        padding: 7px 16px;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        border-radius: 8px;
        line-height: 24px;
        cursor: pointer;
        transition: hover, 0.4s;
      }

      .login {
        column-gap: 16px;
        border: 1px solid #D9D9D9;
        background: #FFF;

        &:hover {
          border-color: #000;
        }
      }

      .create-account {
        color: #fff;
        border: 1px solid #18067D;
        background: #18067D;

        &:hover {
          background: #000;
          border-color: #000;
        }
      }
    }

  }

  .notification-button-container {
    display: flex;
    column-gap: 20px;
    align-items: center;
  }
`;

export const StyledAvatarImg = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;

  &:hover {
    box-shadow: 0 0 0 1px #18067D;
  }
`;

export const StyledLogo = styled(LogoIcon)`
  width: 36px;
  height: 42px;
  border: 0;
  padding: 0;
`;

