import {
    CLEAR_LOGIN_INFO,
    FILL_LOGIN_INFO,
    HIDE_CHOOSE_ROLE_MODAL,
    HIDE_CONFIRM_EMAIL_MODAL,
    HIDE_LOG_IN_MODAL,
    HIDE_REGISTRATION_THIRD_STEP_MODAL,
    HIDE_REVIEW_HOMEWORK_MODAL,
    HIDE_REVIEW_RESULT_MODAL,
    HIDE_SIGN_UP_MODAL,
    HIDE_UPLOAD_RESULT_MODAL,
    SHOW_CHOOSE_ROLE_MODAL,
    SHOW_CONFIRM_EMAIL_MODAL,
    SHOW_LOG_IN_MODAL,
    SHOW_REGISTRATION_THIRD_STEP_MODAL,
    SHOW_REVIEW_HOMEWORK_MODAL,
    SHOW_REVIEW_RESULT_MODAL,
    SHOW_SIGN_UP_MODAL,
    SHOW_UPLOAD_RESULT_MODAL
} from "./modalConstants";


const initialState = {
    showChooseRoleModal: false,
    showLogInModal: false,
    showSignUpModal: false,
    showConfirmEmailModal: false,
    showRegistrationThirdStepModal: false,
    showUploadResultModal: false,
    taskForUploadModal: {},
    showReviewResultModal: false,
    taskForReview: {},
    showReviewHomeworkModal: false,
    callbackAfterLogin: null,
    loginText: "",
}

export function modalsStore(state = initialState, action) {
    const actions = {
        [SHOW_CHOOSE_ROLE_MODAL]: {
            ...state,
            showChooseRoleModal: true
        },
        [HIDE_CHOOSE_ROLE_MODAL]: {
            ...state,
            showChooseRoleModal: false
        },
        [SHOW_LOG_IN_MODAL]: {
            ...state,
            showLogInModal: true,
            loginText: action.loginText || state.loginText,
            callbackAfterLogin: action.callbackAfterLogin || state.callbackAfterLogin,
        },
        [FILL_LOGIN_INFO]: {
            ...state,
            loginText: action.loginText || state.loginText,
            callbackAfterLogin: action.callbackAfterLogin || state.callbackAfterLogin,
        },
        [HIDE_LOG_IN_MODAL]: {
            ...state,
            showLogInModal: false,
        },
        [CLEAR_LOGIN_INFO]: {
            ...state,
            callbackAfterLogin: null,
            loginText: ""
        },
        [SHOW_SIGN_UP_MODAL]: {
            ...state,
            showSignUpModal: true,
            showChooseRoleModal: false
        },
        [HIDE_SIGN_UP_MODAL]: {
            ...state,
            showSignUpModal: false
        },
        [SHOW_CONFIRM_EMAIL_MODAL]: {
            ...state,
            showConfirmEmailModal: true,
            showSignUpModal: false
        },
        [HIDE_CONFIRM_EMAIL_MODAL]: {
            ...state,
            showConfirmEmailModal: false
        },
        [SHOW_REGISTRATION_THIRD_STEP_MODAL]: {
            ...state,
            showRegistrationThirdStepModal: true
        },
        [HIDE_REGISTRATION_THIRD_STEP_MODAL]: {
            ...state,
            showRegistrationThirdStepModal: false
        },
        [SHOW_UPLOAD_RESULT_MODAL]: {
            ...state,
            showUploadResultModal: true,
            taskForUploadModal: action.taskForUploadModal || state.taskForUploadModal,
        },
        [HIDE_UPLOAD_RESULT_MODAL]: {
            ...state,
            showUploadResultModal: false,
            taskForUploadModal: {},
        },
        [SHOW_REVIEW_RESULT_MODAL]: {
            ...state,
            showReviewResultModal: true,
            taskForReview: action.taskForReview || state.taskForReview,
        },
        [HIDE_REVIEW_RESULT_MODAL]: {
            ...state,
            showReviewResultModal: false,
            taskForReview: {},
        },
        [SHOW_REVIEW_HOMEWORK_MODAL]: {
            ...state,
            showReviewHomeworkModal: true,
        },
        [HIDE_REVIEW_HOMEWORK_MODAL]: {
            ...state,
            showReviewHomeworkModal: false,
        }
    }


    return actions[action.type] || state
}