import {Category, SpokenLanguage} from "../types";


const getTeacherSpokenLanguages = (languages: SpokenLanguage[]): string => languages.reduce((acc: string[], item) => [...acc, item.name], []).join(", ");

const getTeacherCategoriesAsString = (categories: Category[]): string => categories.reduce((acc: string[], item) => [...acc, item.name], []).join(", ");

const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

const defineRangeOrSinglePrice = (minPricePerHour: number | undefined, maxPricePerHour: number | undefined): string => {
  if (minPricePerHour == null || maxPricePerHour == null) return "";

  return minPricePerHour === maxPricePerHour ? `₴${minPricePerHour}` : `₴${minPricePerHour} - ₴${maxPricePerHour}`;
};

export {getTeacherSpokenLanguages, getTeacherCategoriesAsString, capitalizeFirstLetter, defineRangeOrSinglePrice};