import styled from 'styled-components';
import {Select} from "antd";

export const DropdownComponentWrapper = styled.div`

`

export const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selector {
    height: ${({height}: { height?: string }) => `${height} !important`};
    border-radius: 8px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }

`;